<script>
  import NavBar from "../components/_NavBar.svelte";
  import LinksToRoutes from "../components/_LinksToRoutes.svelte";
  import { ChevronLeftIcon, ChevronRightIcon } from "svelte-feather-icons";
  import "bulma";
  // "test",
  // let routes = ["/structures", "/departments", "/people", "/roles"];
  let menuHidden = false;

  const hideMenu = () => {
    menuHidden = true;
  };
  const showMenu = () => {
    menuHidden = false;
  };
</script>

<NavBar />

<div class="columns">
  {#if !menuHidden}
    <div
      class="column is-narrow is-hidden-touch"
      style="padding:1rem; margin-top:3rem"
    >
      <div class="sticky-top">
        <LinksToRoutes hideLabelsOnDesktop={false} />
      </div>
      <div class="fixed-bottom-left">
        <div class="my-button" on:click={hideMenu} title="Απόκρυψη μενού">
          <ChevronLeftIcon size="1.5x" />
        </div>
      </div>
    </div>
  {:else}
    <div class="fixed-bottom-left is-hidden-touch">
      <div class="my-button" on:click={showMenu} title="Εμφάνιση μενού">
        <ChevronRightIcon size="1.5x" />
      </div>
    </div>
  {/if}
  <div class="column" data-routify="scroll-lock">
    <section class="section">
      <div class="container">
        <slot />
      </div>
    </section>
  </div>
</div>


<!-- <section class="section">
  <div class="container">
    <div class="columns">

      <div class="column is-narrow">
        <aside class="menu">
          <ul class="menu-list">
            {#each routes as r}
              <li>
                <a href={$url(r)} class:is-active={$isActive(r)}>{r}</a>
              </li>
            {/each}
          </ul>
        </aside>

      </div>

      <div class="column">
        <slot />
      </div>

    </div>
  </div>
</section> -->

<style>
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 6rem;
  }

  :global(.sticky-top) {
    position: -webkit-sticky;
    position: sticky;
    top: 6rem;
    /* z-index: 30; */
  }

  .fixed-bottom-left {
    position: fixed;
    bottom: 5rem;
    left: 0.5rem;
  }

  .my-button {
    cursor: pointer;
  }

  .my-button:hover {
    transform: scale(1.2);
  }
</style>
