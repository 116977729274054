<script lang="ts">
  export let name = "test";
  export let length = 2;
  export let width = 3;
  let colorIndex = 0;
  let initials = "";

  const palette = [
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#00BCD4",
    "#4CAF50",
    "#607D8B",
    "#f44336",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#9E9E9E",
    "#8BC34A",
    "#795548",
    "#CDDC39",
  ];

  $: if (name && name.length) {
    let words = name.trim().split(" ");
    if (words.length > 1) {
      initials = words[0][0] + words[1][0];
    } else {
      initials = words[0][0];
    }

    initials = initials.toUpperCase();

    let charIndex = 0;
    for (let i = 0; i < initials.length; i++) {
      charIndex = initials.charCodeAt(i);
    }
    colorIndex = charIndex % palette.length;
  }
</script>

<svelte:options tag="avatar-component" />

<div
  style="height: {width}rem; width: {width}rem; border-radius: 50%; background: {palette[colorIndex]};
  color: white; font-size: {width / 2}rem; position:relative; font-family:sans"
>
  <div class="centered">{initials}</div>
</div>

<style>
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
</style>
