<script>
   
  import Open1Logo from "./Open1Logo.svelte";

  import { isActive, url, params } from "@sveltech/routify";

  import RefreshTukanga from "../components/_RefreshTukanga.svelte";

  import LinksToRoutes from "./_LinksToRoutes.svelte";

  import { LogOutIcon } from "svelte-feather-icons";

  import Search from "./Search.svelte";

  import Avatar from "./Avatar.svelte";

  import { logout, profile } from "../auth.js";

  let logging_out = false;

  let logout_with_loading = () => {
    logging_out = true;
    logout();
  };

  let activatedMenu = false;

  const toggleMenu = () => {
    activatedMenu = !activatedMenu;
  };

  const toggleLogout = () => {
    let answer = confirm(
      "Να γίνει αποσύνδεση από " + $profile.userInfo.name + " (" + $profile.customInfo.org + ");"
    );
    if (answer) {
      logging_out = true;
      logout();
    }
  };

  $: if ($params) {
    activatedMenu = false;
  }
</script>

<div class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
  <!-- <div class="navbar-brand" style="padding-left:.5rem; padding-right:.5rem">
    <a class="navbar-item" href="/">
      <UsersIcon size="1.5x" />
      <span class="is-hidden-touch">
        &nbsp;
        {#if $profile && $profile.userInfo}{$profile.customInfo.org}{/if}
      </span>
    </a> 
  </div> -->

  <div class="is-hidden-touch navbar-start">
    <a class="navbar-item is-size-4" href={$url("../structures")}>
      <Open1Logo name="ORG" />
    </a>
    <LinksToRoutes />
  </div>

  <div class="navbar-brand" style="flex:1">
    <div class="navbar-item" style="flex:1">
      &nbsp;
      <Search />
    </div>
    <div class="navbar-item">
      <RefreshTukanga />
    </div>
    <span
      role="button"
      on:click={toggleMenu}
      class:is-active={activatedMenu}
      class="navbar-burger burger"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarMenu"
    >
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </span>
  </div>

  <div
    id="navbarMenu"
    class="navbar-menu is-hidden-desktop"
    class:is-active={activatedMenu}
  >
    <div class="navbar-start">
      <LinksToRoutes />
    </div>

    <div class="navbar-end level">
      <div class="is-hidden-desktop navbar-item">
        <hr />
        <button
          title="Αποσύνδεση"
          on:click={logout_with_loading}
          class="button is-light"
          class:is-loading={logging_out}
        >
          <!-- Log out -->
          <LogOutIcon />
          <span>&nbsp; Αποσύνδεση</span>
        </button>
      </div>
    </div>
  </div>

  {#if $profile && $profile.userInfo}
    <a href="#" class="navbar-item is-hidden-touch" on:click={toggleLogout}>
      <span title={$profile.userInfo.name}>
        <Avatar name={$profile.userInfo.name} />
      </span>
    </a>
  {:else}
     <a href="#" class="navbar-item is-hidden-touch" on:click={logout}>
      Αποσύνδεση
    </a>   
  {/if}
</div>
