<script>
    import { axiosAuth, commons } from "../auth.js";
    import { RefreshCwIcon } from "svelte-feather-icons";
    import { toast } from "@zerodevx/svelte-toast";
    import { charts } from "../corpjobperson.js";
    import { goto } from "@sveltech/routify";
    import {lastSeenLog} from "../pages/logs/logsStore.js";

    const TUKANGA_URL = window.env
        ? window.env.TUKANGA_URL
        : __process.env.TUKANGA_URL;

    const logs = window.env ? window.env.LOGS : __process.env.LOGS;

    let updatingTukanga = false;

    let updatingHrms = false;

    let updatingHrmsPreview = false;

    async function hrmsUpdate(settings) {
        let preview = false;

        if (settings && settings.preview) {
            preview = true;
        }

        let previewString = preview ? "true" : "false";

        if ($commons && $commons.orgId) {
            if (preview) {
                updatingHrmsPreview = true;
            } else {
                updatingHrms = true;
            }
            try {
                const res = await $axiosAuth.put(
                    "/aade/org/" +
                        $commons.orgId +
                        "/sync?preview=" +
                        previewString,
                );
                setTimeout(() => {
                    $lastSeenLog=null;
                    $goto("/logs/:log", {log: res.data.data.id });
                    open = false;
                }, 0);
            } catch (error) {
                toast.push("Σφάλμα στην επικοινωνία με το HRMS");
                throw new Error(error);
            } finally {
                if (preview) {
                    updatingHrmsPreview = false;
                } else {
                    updatingHrms = false;
                }
            }
        }
    }

    async function tukangaUpdate() {
        console.log("calling tukanga");

        updatingTukanga = true;
        try {
            const res = await $axiosAuth.post(
                TUKANGA_URL + "api/orgchart/refreshCache",
            );
            toast.push("Επιτυχία ενημέρωσης");
        } catch {
            toast.push("Σφάλμα στην ενημέρωση οργανογράμματος");
        } finally {
            // updatingTukanga = false;
        }

        try {
            const res = await Promise.all(
                $charts.map(async (chart) => {
                    let url =
                        TUKANGA_URL +
                        "api/orgchart/setRootSupervisor?orgId=" +
                        $commons.orgUuid +
                        "&chartId=" +
                        chart.uuid;
                    let res = await $axiosAuth.post(url);
                }),
            );
            // toast.push("Επιτυχία ενημέρωσης διαχειριστή");
        } catch (error) {
            toast.push("Σφάλμα στην ενημέρωση διαχειριστή");
        } finally {
            updatingTukanga = false;
        }
    }

    let open = false;

    const cancel = () => (open = false);

    function openModal() {
        open = true;
    }
</script>

<div class="modal" class:is-active={open}>
    <div in:fade|local on:click={cancel} class="modal-background" />
    <div transition:fly|local={{ duration: 250, y: 10 }} class="box modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Συγχρονισμός</p>
            <button class="delete" on:click={cancel} aria-label="close" />
        </header>
        <section class="modal-card-body">
            <button
                class="button is-large"
                class:is-loading={updatingHrmsPreview}
                on:click={() => hrmsUpdate({ preview: true })}
                >HRMS προεπισκόπηση χωρίς αλλαγές</button
            >

            <hr />

            <button
                class="button is-large"
                class:is-loading={updatingHrms}
                on:click={() => hrmsUpdate({ preview: false })}
                >HRMS ενημέρωση</button
            >

            <hr />

            {#if TUKANGA_URL}
                <button
                    class="button is-large"
                    on:click={tukangaUpdate}
                    class:is-loading={updatingTukanga}>PROCESS ενημέρωση</button
                >
            {/if}
        </section>
    </div>
</div>

{#if logs}
    <button
        title={"Συγχρονισμός"}
        on:click={openModal}
        class="button"
        class:is-loading={updatingTukanga}
    >
        <span class="icon">
            <RefreshCwIcon size="24" />
        </span>
    </button>
{:else if TUKANGA_URL}
    <button
        title={"Ενημέρωση του " + TUKANGA_URL}
        on:click={tukangaUpdate}
        class:is-loading={updatingTukanga}
        class="button"
    >
        <span class="icon">
            <RefreshCwIcon size="24" />
        </span>
    </button>
{/if}
