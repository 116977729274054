import axios from "axios";

import {
    profile,
    commons,
    axiosAuth,
    cookie_exists,
    loggedIn,
    errorWithSSO,
    errorWithCustomInfo,
    errorWithCustomInfoAttributes
} from "./auth.js";


const CLIENT_ID = window.env ? window.env.CLIENT_ID : __process.env.CLIENT_ID;
const APPLICATION = window.env ? window.env.APPLICATION : __process.env.APPLICATION;
const SSO_URL = window.env ? window.env.SSO_URL : __process.env.SSO_URL;
const BASE_URL = window.env ? window.env.BASE_URL : __process.env.BASE_URL;

//wso2
const FED_TYPE = window.env ? window.env.FED_TYPE : __process.env.FED_TYPE;
const FED_ORG = window.env ? window.env.FED_ORG : __process.env.FED_ORG;

const CURRENT_URL = window.location;
const HOMEPAGE_URL = window.location.origin + "/";

// gets the cookie with 'name' and returns it as an object, else returns false
export const getCookie = name => {
    //console.log("Getting cookie....");

    let cookie = document.cookie.split("; ").reduce((r, v) => {
        const parts = v.split("=");
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, "");

    if (cookie) {
        // console.log("Cookie: "+cookie);
        cookie_exists.set(true);
        return JSON.parse(cookie);
    } else return false;
};

export const loginOLD = () => {
    console.log("Redirecting to login page....");
    //redirect to sso login page, declaring your CLIENT_ID, APPLICATION and CURRENT_URL
    //wso2
    if (FED_TYPE) {
        window.location.href =
            SSO_URL +
            "extended_api/feds/federationPrelogin?fedType=" +
            FED_TYPE +
            "&organization=" +
            FED_ORG +
            "&response_type=token&client_id=" +
            CLIENT_ID +
            "&application=" +
            APPLICATION +
            "&redirect_uri=" +
            CURRENT_URL;
    }
    else {
        window.location.href =
            SSO_URL +
            "authorize?response_type=token&client_id=" +
            CLIENT_ID +
            "&application=" +
            APPLICATION +
            "&redirect_uri=" +
            CURRENT_URL;
    }


    // if you prefer to always redirect to the home page, use: HOMEPAGE_URL instead of CURRENT_URL
};

export const logoutOLD = async () => {
    console.log("log out...");

    // 0. get SSO cookie
    let ssoAuth = getCookie("ssoAuth");
    let idToken = '';

    if (ssoAuth) {
        // 1. expire SSO cookie
        document.cookie =
            "ssoAuth= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/";

        if (ssoAuth.idToken) {
            idToken = ssoAuth.idToken;
        }
    } else {
        console.log("sso cookie was not found");
    }

    // 2. redirect to logout url


    //wso2
    if (FED_TYPE) {

        window.location.href =
            SSO_URL + "extended_api/feds/endsession?id_token_hint=" + idToken +
            "&post_logout_redirect_uri=" + HOMEPAGE_URL;

    } else {

        window.location.href =
            SSO_URL +
            "endsession?id_token_hint=" + idToken + "&post_logout_redirect_uri=" + HOMEPAGE_URL;
    }

};

export const authenticateOLD = async () => {
    console.log("Running authenticate...");
    //
    // if ('serviceWorker' in navigator) {
    //
    //           navigator.serviceWorker
    //               .register('service-worker.js', {scope: '/'})
    //               .then((registration) => {
    //                 console.log("Service Worker Registered", registration);
    //                 //alert('There is a service worker in the background');
    //               })
    //           .catch((err) => {
    //             console.log("SW Registration failed");
    //             //alert('There is no service worker in the background');
    //           });
    //
    // }

    // look for 'ssoAuth'
    let ssoAuth = getCookie("ssoAuth");

    if (ssoAuth) {

        // create axios instance with authorization headers
        // DONE: make this instance available in a store. To be used by every component that makes http requests
        const tmpaxiosauth = axios.create({
            baseURL: BASE_URL,
            headers: {
                Authorization:
                    "Bearer " +
                    ssoAuth.accessToken +
                    "&organization=" +
                    ssoAuth.organization,
                // 'Accept': 'application/json, text/plain, */*',
                "Content-Type": "application/json"
            }
        });

        axiosAuth.set(tmpaxiosauth);
        // defaults.set(tmpaxiosauth.defaults);
        loggedIn.set(true);


        if (ssoAuth.organization && ssoAuth.accessToken) {
            console.log("Getting user info from sso....");
            // get user info from SSO
            axios
                .get(SSO_URL + "custom/userinfo", {
                    headers: {
                        Authorization: "Bearer " + ssoAuth.accessToken
                    },
                    params: {
                        organizationId: ssoAuth.organization,
                        application: APPLICATION
                    }
                })
                .then(
                    result => {
                        console.log("from sso profile", result.data);
                        let userProfile = result.data; //DONE: make the profile (or parts of the profile) available in a store


                        //   $: if (userProfile != undefined) $profile = userProfile; //store will be updated when userProfile info is set.
                        if (userProfile) {
                            profile.set(userProfile);
                        }

                        let userName = "...";

                        //userName setup
                        if (
                            userProfile &&
                            userProfile.userInfo &&
                            userProfile.userInfo.name
                        ) {
                            userName = userProfile.userInfo.name;
                        } else {
                            // TODO: handle problem with userProfile - Interceptor?
                        }

                        // custom info setup
                        if (userProfile && userProfile.customInfo) {
                            let customInfo = userProfile.customInfo;

                            if (
                                customInfo.openonesso_scope &&
                                customInfo.openonesso_authority &&
                                customInfo.orgId &&
                                customInfo.orgUuid
                            ) {
                                //common attributes are found

                                let ldap = false;

                                if (customInfo.openonesso_authority) {

                                    let index = customInfo.openonesso_authority.findIndex(function (x) { return x == 'ldap' });

                                    if (index > -1) {
                                        ldap = true;
                                    } else {
                                        ldap = false;
                                    }
                                }

                                //   $: if (userProfile != undefined) $commons = common_attr; //store will be updated when userProfile info is set.
                                commons.set({
                                    openonesso_scope: customInfo.openonesso_scope,
                                    openonesso_authority: customInfo.openonesso_authority,
                                    orgId: customInfo.orgId,
                                    orgUuid: customInfo.orgUuid,
                                    ldap: ldap
                                });

                            } else {
                                errorWithSSO.set(true);
                                errorWithCustomInfoAttributes.set(true);
                                // logout();
                            }
                        } else {
                            // TODO: handle individual problems of missing profile attributes - Interceptor?
                            errorWithSSO.set(true);
                            errorWithCustomInfo.set(true);
                            // logout();
                        }
                    },
                    error => {
                        // errorWithSSO.set(true);
                        console.log("error getting user profile", error);
                        // TODO: handle this error - Interceptor?
                        logoutOLD();
                    }
                );
        }
    } else {
        console.log("Logging in for first time...");
        //logged in first time logic
        if (window.location.href.includes("token")) {
            // OLDEN SSO
            console.log("Logging in for first time - token detected...");
            loggedIn.set(true);

            // extract info from the url...

            let tmpArray = window.location.href.split("#");

            // extract organization
            let organization = tmpArray[0].split("organization=")[1];

            // extract parameters
            let tmpArray2 = tmpArray[1].split("&");

            let parameters = [];

            for (let i = 0; i < tmpArray2.length; i++) {
                let param = tmpArray2[i].split("=");
                parameters[param[0]] = param[1];
            }

            // if everything seems OK: save SSO cookie and redirect to redirectUrl
            if (
                organization &&
                parameters.id_token &&
                parameters.access_token &&
                parameters.expires_in
            ) {
                // convert expires_in to GMT String
                let expires = new Date();
                expires.setSeconds(
                    expires.getSeconds() + parseInt(parameters.expires_in)
                );
                expires = expires.toGMTString();

                let ssoAuth = {
                    accessToken: parameters.access_token,
                    idToken: parameters.id_token,
                    organization: organization,
                    expires: expires,
                    duration: parameters.expires_in
                };

                // save cookie
                // ** With a path parameter, you can tell the browser what path the cookie belongs to. By default, the cookie belongs to the current page. **
                // ** we set the cookie to root path: '/'

                document.cookie = "ssoAuth=" + JSON.stringify(ssoAuth) + "; path=/";

                // redirect
                let redirectUrl = window.location.href.split("?")[0]; //
                console.log("redirectURL: " + redirectUrl);
                window.location.href = redirectUrl;
            } else {
                errorWithSSO.set(true);
                // console.log("error with sso:", window.location.href);
            }
        } else {
            // enable the following line, if you wish to auto redirect the user to the login page, when they are not logged in
            //login();
        }
    }
};