<script>
  import SortableList from "../../components/_DragDropList.svelte";
  import AutoComplete from "simple-svelte-autocomplete";
  import { params, url, goto, beforeUrlChange } from "@sveltech/routify";
  import { axiosAuth, commons } from "../../auth.js";
  import { charts, units, roles, people, groups } from "../../corpjobperson.js";
  import {
    PlusIcon,
    Link2Icon,
    Trash2Icon,
    SaveIcon,
  } from "svelte-feather-icons";
  import { fade, scale, fly } from "svelte/transition";
  import { removeAccents } from "../../removeaccents.js";
  import { toast } from "@zerodevx/svelte-toast";

  let group = {};
  let open = false;
  let openDelete = false;
  let newGroupName;
  let newGroupDescription;
  let saving = false;
  let assignments = [];
  let filteredAssignments = [];
  let selectedChart = $params.chart;
  let selectedAssignment = null;
  let showAddAssignment = false;
  let waitingForAssignments = false;

  let include_people = true;
  let include_roles = true;
  let include_units = true;

  const showAssignment = () => {
    if ($charts.length == 1) {
      selectedChart = $charts[0];
    }
    showAddAssignment = true;
  };

  const findAssignment = (r, s) => {
    if (
      r.chartUuid == s.chartUuid &&
      r.departmentUuid == s.departmentUuid &&
      r.jobDescriptionUuid == s.jobDescriptionUuid &&
      r.personUuid == s.personUuid
    )
      return true;
    else return false;
  };

  const removeAll = () => {
    let answer = confirm("Να αφαιρεθούν όλες οι αναθέσεις από την ομάδα;");
    if (answer) {
      group.references = [];
    }
  };

  const addAll = () => {
    let answer = confirm(
      "Να προστεθούν όλες οι αναθέσεις από το οργανόγραμμα " +
        selectedChart.name +
        ";"
    );
    if (answer && group.references && assignments) {
      assignments.forEach((a) => {
        let found = group.references.find((r) => findAssignment(r, a));
        if (!found) {
          group.references = [a, ...group.references];
        }
      });
      // group.references = [...assignments];
    }
  };

  const addAssignment = () => {
    if (selectedAssignment && group.references) {
      let found = group.references.find((r) =>
        findAssignment(r, selectedAssignment)
      );
      if (found) {
        group.references.splice(group.references.indexOf(found), 1);
      }
      group.references = [selectedAssignment, ...group.references];
    }
    setTimeout(() => (selectedAssignment = null), 0);
  };

  const sortReferences = (ev) => {
    group.references = ev.detail;
  };

  const deleteReference = (reference) => {
    group.references.splice(group.references.indexOf(reference), 1);
    group.references = group.references;
  };

  // /v2/{clientUuid}/synonyms/{synonymUuid}
  async function deleteGroup() {
    try {
      const res = await $axiosAuth.delete(
        "/v2/" + $commons.orgUuid + "/synonyms/" + $params.group
      );
    } catch (error) {
    } finally {
      openDelete = false;
      $goto("/group/all");
    }
  }

  function openNewGroupModal() {
    newGroupName = null;
    newGroupDescription = null;
    open = true;
  }

  function cancelDeleteDialog() {
    openDelete = false;
  }

  function cancel() {
    open = false;
  }

  async function addGroup() {
    let result;

    try {
      const res = await $axiosAuth.post("/v2/" + clientUuid + "/synonyms/", {
        name: newGroupName,
        description: newGroupDescription,
        references: [],
      });

      result = await res.data;
    } catch (error) {
      alert(error);
    } finally {
      open = false;
      if (result) {
        // alert(JSON.stringify(result));
        $groups = [...$groups, result];
        $goto("/group/:group", { group: result.uuid.replace("SLINK->", "") });
      }
    }
  }

  async function saveGroup() {
    saving = true;

    let result;

    // alert(JSON.stringify(group));

    try {
      const res = await $axiosAuth.put(
        "/v2/" + clientUuid + "/synonyms/" + $params.group,
        group
      );
      result = await res.data;
      toast.push("Αποθηκεύτηκε");
    } catch (error) {
      alert(error);
    } finally {
      setTimeout(() => (saving = false), 400);
    }
  }

  function getUnitName(uuid) {
    let found = $units.filter((unit) => uuid === unit.uuid);
    if (found && found.length) {
      console.log("got herer", found);
      return found[0].name;
    } else {
      return "No departments in this chart";
    }
  }

  $: if (
    (assignments && include_people) ||
    !include_people ||
    include_roles ||
    !include_roles ||
    include_units ||
    !include_units
  ) {
    filterAssignments();
  }

  function filterAssignments() {
    let transformedAssignments = assignments
      .filter(function (x) {
        return !x.disabled;
      })
      .map(function (x) {
        // we need to create a deep copy. Otherwise the assignments contents will be altered unintentionally

        let y = {
          chartUuid: x.chartUuid,
          chartTitle: x.chartTitle,
          departmentUuid: x.departmentUuid,
          departmentTitle: x.departmentTitle,
          jobDescriptionUuid: x.jobDescriptionUuid,
          jobDescriptionTitle: x.jobDescriptionTitle,
          personUuid: x.personUuid,
          personFullName: x.personFullName,
        };
        if (!include_people) {
          y.personUuid = "";
          y.personFullName = "";
        }
        if (!include_roles) {
          y.jobDescriptionUuid = "";
          y.jobDescriptionTitle = "";
        }
        if (!include_units) {
          y.departmentUuid = "";
          y.departmentTitle = "";
        }
        return y;
      });

    // remove dublicates
    filteredAssignments = transformedAssignments.reduce(function (acc, item) {
      let found = false;
      for (let i = 0; i < acc.length; i++) {
        let x = acc[i];
        if (
          x.personUuid == item.personUuid &&
          x.jobDescriptionUuid == item.jobDescriptionUuid &&
          x.departmentUuid == item.departmentUuid
        ) {
          found = true;
          break;
        }
      }
      if (!found) {
        acc.push(item);
      }
      return acc;
    }, []);
  }

  async function getAllAssignments() {
    if (selectedChart) {
      waitingForAssignments = true;
      try {
        let url =
          "/v2/" + $commons.orgUuid + "/assignments/" + selectedChart.uuid;
        const res = await $axiosAuth.get(url);
        assignments = await res.data;
      } catch (error) {
        throw new Error(error);
        console.log(error);
        console.log(res);
      } finally {
        // alert(JSON.stringify($groups));
        waitingForAssignments = false;
      }
    }
  }

  async function getGroups(clientUuid) {
    try {
      const res = await $axiosAuth.get("/v2/" + clientUuid + "/synonyms/");
      $groups = await res.data;
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    } finally {
      // alert(JSON.stringify($groups));
    }
  }

  async function getGroup(clientUuid) {
    try {
      const res = await $axiosAuth.get(
        "/v2/" + clientUuid + "/synonyms/" + $params.group
      );
      group = await res.data;
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    }
  }

  const labelFunctionForChart = (item) => {
    let label = item.name;
    return label;
  };

  const labelFunctionForReference = (item) => {
    let label = "";
    if (item.personFullName) {
      label += item.personFullName + " ";
    }
    if (item.jobDescriptionTitle) {
      label += item.jobDescriptionTitle + " ";
    }
    if (item.departmentTitle) {
      label += item.departmentTitle + " ";
    }
    if (item.chartTitle) {
      label += item.chartTitle;
    }

    return label;
  };

  let clientUuid = 0;

  $: {
    clientUuid = $commons.orgUuid;

    if (clientUuid && $params && $params.group) {
      // let promise0 = getGroups(clientUuid); //not needed: groups are initialized at the search component
      if ($params.group != "all") {
        let promise1 = getGroup(clientUuid);
      }
    }
  }
</script>

<div class="columns columns-reverse-mobile">
  {#if $params.group == "all"}
    <div class="column column-reverse-mobile menu">
      <div in:fade class="title">Ομάδες</div>
      {#if $groups && $groups.length}
        <ul class="menu-list">
          {#each $groups as group}
            <li in:fade>
              <a
                href={$url("/group/:group", {
                  group: group.uuid.replace("SLINK->", ""),
                })}
                class="bordered-bottom"
              >
                {group.name}
              </a>
            </li>
          {/each}
        </ul>
      {/if}

      <div class="sticky-bottom white-fade">
        <button
          class="button is-link sticky-bottom"
          on:click={openNewGroupModal}
        >
          <PlusIcon size="1.5x" />
          &nbsp; Νέα ομάδα
        </button>
      </div>
    </div>

    {#if open}
      <div class="modal" class:is-active={open}>
        <div out:fade|local on:click={cancel} class="modal-background" />
        <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Νέα ομάδα</p>
            <button class="delete" on:click={cancel} aria-label="close" />
          </header>
          <section class="modal-card-body">
            <div class="field">
              <label class="label">Όνομα</label>
              <div class="control">
                <input
                  type="text"
                  bind:value={newGroupName}
                  placeholder=""
                  class="input"
                  autofocus
                />
              </div>
            </div>
            <div class="field">
              <label class="label">Περιγραφή</label>
              <div class="control">
                <textarea
                  type="text"
                  bind:value={newGroupDescription}
                  placeholder=""
                  class="textarea"
                />
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button
              disabled={!newGroupName}
              on:click={addGroup}
              class="button is-pulled-left is-link"
            >
              <!-- <CheckIcon size="1.5x" /> -->
              &nbsp; Προσθήκη
            </button>
            <button on:click={cancel} class="button is-light pull-right">
              Άκυρο
            </button>
          </footer>
        </div>
      </div>
    {/if}
  {/if}

  <!-- {#if $units.length && $roles.length} -->
  {#if true}
    <!-- -->
    {#if $params.group != "all" && group}
      <div
        class="column column-reverse-mobile is-full"
        in:fade|local
        out:fade|local
      >
        <div class="">
          <div class="title">{group.name}</div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">
                <!-- Name -->
                Όνομα
              </label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input
                    bind:value={group.name}
                    class="input"
                    type="text"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">
                <!-- Description -->
                Περιγραφή
              </label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input
                    type="text"
                    bind:value={group.description}
                    placeholder=""
                    class="input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr />

        {#if !showAddAssignment}
          <div class="level">
            <div class="level-item">
              <button class="button" on:click={showAssignment}>
                <PlusIcon size="1.5x" />
                &nbsp; Νέα ανάθεση
              </button>
            </div>
          </div>
        {/if}

        {#if showAddAssignment}
          <div in:scale|local class="box has-background-light">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">
                  <!-- Chart -->
                  Οργανόγραμμα
                </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <AutoComplete
                    items={$charts}
                    bind:selectedItem={selectedChart}
                    onChange={getAllAssignments}
                    keywordsFunction={labelFunctionForChart}
                    labelFunction={labelFunctionForChart}
                    keywordsCleanFunction={removeAccents}
                    textCleanFunction={removeAccents}
                    placeholder="Αναζήτηση..."
                  />
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">
                  <!-- Επιλογές  -->
                </label>
              </div>
              <div class="field-body">
                <div class="field tags are-medium">
                  <label class="checkbox tag">
                    <input type="checkbox" bind:checked={include_units} />
                    &nbsp; Οργανωτικές μονάδες
                  </label>

                  <label class="checkbox tag">
                    <input type="checkbox" bind:checked={include_roles} />
                    &nbsp; Ρόλοι
                  </label>

                  <label class="checkbox tag">
                    <input type="checkbox" bind:checked={include_people} />
                    &nbsp; Πρόσωπα
                  </label>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">
                  <!-- + Assignment -->
                  Νέα ανάθεση
                </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <AutoComplete
                    items={filteredAssignments}
                    bind:selectedItem={selectedAssignment}
                    onChange={addAssignment}
                    keywordsFunction={labelFunctionForReference}
                    labelFunction={labelFunctionForReference}
                    keywordsCleanFunction={removeAccents}
                    textCleanFunction={removeAccents}
                    placeholder="Αναζήτηση..."
                  />
                </div>
              </div>
            </div>
          </div>
        {/if}

        <div class="">
          <div class="level">
            <div class="flex">&nbsp;</div>
            <div class="level-right">
              <div class="buttons">
                {#if selectedChart && !waitingForAssignments}
                  <button
                    in:scale|
                    local
                    class="button is-small is-light is-info"
                    on:click={addAll}
                  >
                    Προσθήκη όλων από {selectedChart.name}
                  </button>
                {/if}
                {#if group && group.references && group.references.length}
                  <button
                    class="button is-small is-light is-danger"
                    on:click={removeAll}
                  >
                    Αφαίρεση όλων
                  </button>
                {/if}
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">
                <!-- References -->
                Αναθέσεις
                {#if group && group.references}({group.references.length}){/if}
              </label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <!-- <div style="word-wrap: break-word; width:400px">
                          {JSON.stringify(group.references)}
                        </div> -->
                  {#if group && group.references && group.references.length}
                    <SortableList
                      list={group.references}
                      let:item
                      on:reordered={sortReferences}
                    >
                      <!-- {#each group.references as ref} -->
                      <div
                        class=""
                        style="border:1px solid lightgrey; border-radius:5px;
                        padding:5px; padding-left:10px; margin-top:5px;
                        margin-bottom:10px"
                      >
                        <div class="level">
                          <div class="flex">
                            <div style="word-wrap: break-word;">
                              {#if item.personFullName}
                                {item.personFullName}
                              {/if}

                              {#if item.jobDescriptionTitle}
                                {item.jobDescriptionTitle}
                              {/if}

                              {#if item.departmentTitle}
                                {item.departmentTitle}
                              {/if}

                              {#if item.chartTitle}{item.chartTitle}{/if}
                            </div>
                            <div />
                          </div>

                          <div class="level-item level-right">
                            <a
                              on:click={deleteReference(item)}
                              class="tag is-medium is-delete"
                            />
                          </div>
                        </div>
                      </div>
                    </SortableList>
                  {/if}
                </div>
              </div>
            </div>
          </div>

          <!-- 
    <div style="word-wrap: break-word; padding:2rem">
      {#if group && group.references}
      {#each group.references as ref}
      <div style="padding:1rem">
      {JSON.stringify(ref)}
      </div>
      {/each}
      {/if}
    </div>
 -->
        </div>

        <hr />

        <div class="sticky-bottom white-fade">
          <div class="level is-mobile">
            <div class="is-hidden-touch">&nbsp;</div>
            <button
              class="button is-link"
              on:click={saveGroup}
              class:is-loading={saving}
            >
              <SaveIcon size="1.5x" />
              <span class="is-hidden-mobile">&nbsp; Αποθήκευση</span>
            </button>

            <button on:click={() => (openDelete = true)} class="button">
              <Trash2Icon size="1.5x" />
              <span class="is-hidden-mobile">&nbsp; Διαγραφή</span>
            </button>

            <div class="is-hidden-touch">&nbsp;</div>
            <div class="is-hidden-touch">&nbsp;</div>
            <div class="is-hidden-touch">&nbsp;</div>
          </div>
        </div>
      </div>
    {/if}
  {/if}
</div>

<!-- delete dialog -->
{#if openDelete}
  <div class="modal" class:is-active={openDelete}>
    <div
      out:fade|local
      on:click={cancelDeleteDialog}
      class="modal-background"
    />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <!-- Delete -->
          Διαγραφή
        </p>
        <button
          class="delete"
          on:click={cancelDeleteDialog}
          aria-label="close"
        />
      </header>
      <section class="modal-card-body">Διαγραφή {group.name};</section>
      <footer class="modal-card-foot">
        <button
          on:click={deleteGroup}
          class="button is-pulled-left is-danger is-light"
        >
          <Trash2Icon size="1.5x" />
          <!-- &nbsp; Delete -->
          &nbsp; Διαγραφή
        </button>
        <button
          on:click={cancelDeleteDialog}
          class="button is-light pull-right"
        >
          <!-- Cancel -->
          Ακύρωση
        </button>
      </footer>
    </div>
  </div>
{/if}

<style>
  .wrap-tag {
    word-wrap: break-word;
  }
</style>
