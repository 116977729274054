<script>
    export let event;
    export let showJSON = false;
</script>

{#if event}
    {#if showJSON}
      <div style="margin-top:1rem">
        <pre>
            {JSON.stringify(event, null, 2)}
        </pre>
      </div>
    {:else}
        <div class="my-event">
            {#if event.message}
                <div class="is-size-6">
                    {event.message}
                </div>
            {/if}
            <div class="tags are-medium" style="margin-top:1rem">
                {#if event.eventType}
                    <div class="tag is-gray">
                        {event.eventType}
                    </div>
                {/if}

                {#if event.personUuid}
                    <div class="tag is-gray">
                        <!-- personUuid:  -->
                        {event.personUuid}
                    </div>
                {/if}

                {#if event.unitUuid}
                    <div class="tag is-gray">
                        <!-- unitUuid:  -->
                        {event.unitUuid}
                    </div>
                {/if}

                {#if event.hrmsUnitId}
                    <div class="tag is-gray">
                        <!-- hrmsUnitId:  -->
                        {event.hrmsUnitId}
                    </div>
                {/if}

                {#if event.hrmsUnitName}
                    <div class="tag is-gray">
                        <!-- hrmsUnitName:  -->
                        {event.hrmsUnitName}
                    </div>
                {/if}

                {#if event.hrmsPersonVAT}
                    <div class="tag is-gray">
                        <!-- hrmsPersonVAT:  -->
                        {event.hrmsPersonVAT}
                    </div>
                {/if}

                {#if event.hrmsPersonFullName}
                    <div class="tag is-gray">
                        <!-- hrmsPersonFullName:  -->
                        {event.hrmsPersonFullName}
                    </div>
                {/if}
            </div>
        </div>
    {/if}
{/if}

<style>
    .my-event {
        border-top: 1px solid #f0f0f0;
        padding: 2rem;
    }
</style>
