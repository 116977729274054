<script>
  import AutoComplete from "simple-svelte-autocomplete";
  import { params, goto, url, isActive } from "@sveltech/routify";
  import { axiosAuth, commons } from "../auth.js";
  import { units, roles, people, groups, charts } from "../corpjobperson.js";
  import { PlusIcon } from "svelte-feather-icons";
  import { fade, scale, fly } from "svelte/transition";
  import {removeAccents} from "../removeaccents.js";

  let selectedItem = null;
  let open = false;

  let supervision = [];
  let filteredRoles = [];

  export let jobDescUuid;

  // /{clientUuid}/{jobDescUuid}/supervision

  // 404 !!!
  // async function getAvailableSupervisions(clientUuid, jobDescUuid) {
  //   try {
  //     const res = await $axiosAuth.get("/" + clientUuid + "/" + jobDescUuid + "/supervision?selected=false");
  //     supervision = await res.data;
  //   } catch (error) {
  //     throw new Error(error);
  //     console.log(error);
  //     console.log(res);
  //   }
  // };

  let addSupervision = () => {
    supervision = [...supervision, selectedItem];
    cancelModal();
  };

  let removeSupervision = item => {
    let index = supervision.indexOf(item);
    if (index !== -1) {
      supervision.splice(index, 1);
      supervision = supervision;
    }
  };

  export const save = async function() {
    try {
      const res = await $axiosAuth.put(
        "/" + clientUuid + "/" + jobDescUuid + "/supervision?date",
        {
          oldSelectedSupervisors: [],
          newSelectedSupervisors: supervision.map(x => {
            return { supervisorJobDescUuid: x.uuid };
          })
        }
      );
    } catch (error) {
      throw new Error(error);
      alert(JSON.stringify(error));
    }
  };

  async function getSupervisions() {
    try {
      const res = await $axiosAuth.get(
        "/" + clientUuid + "/" + jobDescUuid + "/supervision?selected=true"
      );
      supervision = await res.data;
      // alert(JSON.stringify(supervision));
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    }
  }

  $: clientUuid = $commons.orgUuid;

  $: if (clientUuid && jobDescUuid) {
    getSupervisions();
  }

  $: if ($roles && jobDescUuid && supervision) {
    filteredRoles = $roles.filter(r => {
      if (r.uuid === jobDescUuid) return false;
      else {
        for (let i = 0; i < supervision.length; i++) {
          if (r.uuid === supervision[i].uuid) return false;
        }
        return true;
      }
    });
  }

  function clear() {
    selectedItem = null;
  }

  let cancelModal = () => {
    open = false;
  };

  let openModal = () => {
    selectedItem = null;
    open = true;
  };


  const labelFunction = item => {
    return item.name;
  };

  const keywordsFunction = item => {
    let label = labelFunction(item);
    return label + " " + removeAccents(label);
  };
</script>

<style>
  .modal-card {
    overflow: visible;
  }

  .modal-card-body {
    overflow: visible;
  }
</style>

{#if supervision}
  <div class="field is-grouped is-grouped-multiline">
    {#each supervision as su}
      <div class="control">
        <div class="tags has-addons">
          <a
            class="tag is-medium is-success is-light"
            href={$url('/role/:role', { role: su.uuid })}>
            {su.name} </a>
          <a
            on:click={removeSupervision(su)}
            class="tag is-medium is-delete is-success is-light" ></a>
        </div>
      </div>
    {/each}
  </div>
{/if}

<button class="button is-light" on:click={openModal}>
  <PlusIcon size="1.5x" />
  &nbsp; Νέα προϊστάμενη θέση
</button>

<!-- add structure modal -->
{#if open}
  <div class="modal" class:is-active={open}>
    <div out:fade|local on:click={cancelModal} class="modal-background" />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card" >
      <header class="modal-card-head">
        <p class="modal-card-title">Νέα προϊστάμενη θέση</p>
        <button class="delete" on:click={cancelModal} aria-label="close" />
      </header>
      <section class="modal-card-body">
        <AutoComplete
          items={filteredRoles}
          bind:selectedItem
          keywordsFunction={labelFunction}
          {labelFunction}
          keywordsCleanFunction={removeAccents}
          textCleanFunction={removeAccents}
          placeholder="Επιλογή..." />
      </section>
      <footer class="modal-card-foot">
        <button
          disabled={!selectedItem}
          on:click={addSupervision}
          class="button is-pulled-left is-link">
          <!-- <CheckIcon size="1.5x" /> -->
          &nbsp; Προσθήκη
        </button>
        <button on:click={cancelModal} class="button is-light pull-right">
          Άκυρο
        </button>
      </footer>
    </div>
  </div>
{/if}
