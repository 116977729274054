<script>
  export let unit;
  import { goto, url, params } from "@sveltech/routify";
  import { PlusIcon } from "svelte-feather-icons";
  import { axiosAuth, commons } from "../../../auth.js";
  import { onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { ChevronDownIcon, ChevronRightIcon } from "svelte-feather-icons";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  const askForNewUnit = () => {
    dispatch("new", {
      parentUuid: unit.uuid,
      disabled: unit.disabled,
    });
  };

  const scrollToDepartment = function () {
    try {
      document.getElementById($params.department).scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "center",
      });
    } catch {
      //for older browsers
      document.getElementById($params.department).scrollIntoView(false);
    }
  };

  onMount(() => {
    if (document.getElementById($params.department)) {
      scrollToDepartment();
    }
  });

  $: if (document.getElementById($params.department)) {
    scrollToDepartment();
  }

  $: clientUuid = $commons.orgUuid;

  let showChildren = true;

  const toggleChildren = () => {
    showChildren = !showChildren;
  };
</script>

{#if unit && unit.children && unit.children.length}
  <span
    class:has-text-white={unit.uuid == $params.department}
    style="margin-top:6px"
    class="icon is-pulled-left"
    on:click={toggleChildren}
  >
    {#if showChildren}
      <ChevronDownIcon size="1x" />
    {:else}
      <ChevronRightIcon size="1x" />
    {/if}
  </span>
{:else}
  <span class="icon is-pulled-left">&nbsp;</span>
{/if}

<a
  id={unit.uuid}
  class:has-background-white-ter={unit.uuid == $params.department}
  class:is-disabled={unit.disabled}
  href={$url("/structure/:chart/department/:department", {
    chart: $params.chart,
    department: unit.uuid,
  })}
>
  <!-- <ChevronRightIcon size="1x" /> -->
  {#if unit.disabled}(Ανενεργό){/if}
  {unit.name}
</a>
{#if unit.uuid == $params.department}
  <button
    title="Νέα οργανωτική μονάδα"
    on:click={askForNewUnit}
    style="margin-top:-2.6rem; margin-right: -1rem"
    class="button is-pulled-right"
  >
    <span class="icon">
      <PlusIcon />
    </span>
  </button>
{/if}

<ul class="menu-list" class:is-hidden={!showChildren}>
  {#if unit && unit.children && unit.children.length}
    {#each unit.children as child (child)}
      <li class="child">
        <svelte:self unit={child} on:new />
      </li>
    {/each}
  {/if}
</ul>

<style>
  .is-disabled {
    opacity: 0.5;
    transition: opacity ease 500ms;
  }
</style>
