import { writable, derived } from 'svelte/store';

import { loginKEYCLOAK, logoutKEYCLOAK, authenticateKEYCLOAK } from './authKEYCLOAK';
import { loginOLD, logoutOLD, authenticateOLD } from './authOLD';

export const axiosAuth = writable([]);
export const profile = writable([]);
export const commons = writable([]);
export const cookie_exists = writable(false);
export const errorWithSSO = writable(false);
export const errorWithCustomInfo = writable(false);
export const errorWithCustomInfoAttributes = writable(false);
export const loggedIn = writable(false);
export const waiting = writable(false);

//Prop to determine whether the stores holding auth and user info have been set.
//This prop should not be set from outside, but binded instead, in order to read its value from the main app component.

export const ready = derived([loggedIn, errorWithSSO, errorWithCustomInfo, profile], ([$loggedIn, $errorWithSSO, $errorWithCustomInfo, $profile]) => ($loggedIn && !$errorWithSSO && !$errorWithCustomInfo && $profile));

//keycloak
const KEYCLOAK_URL = window.env ? window.env.KEYCLOAK_URL : __process.env.KEYCLOAK_URL;

export const login = () => {
    if (KEYCLOAK_URL) {
        loginKEYCLOAK();
    } else {
        loginOLD();
    };
}

export const logout = async () => {
    if (KEYCLOAK_URL) {
        logoutKEYCLOAK();
    } else {
        logoutOLD();
    }
};

export const authenticate = async () => {
    if (KEYCLOAK_URL) {
        authenticateKEYCLOAK();
    } else {
        authenticateOLD();
    }
};