<script>
	import { url } from "@sveltech/routify";
	import { axiosAuth, commons, profile } from "../../auth.js";
	import { fade, scale, fly } from "svelte/transition";
	import { toast } from "@zerodevx/svelte-toast";
	import Spinner from "svelte-spinner";
	import Switch from "svelte-switch";
	import Assign from "../../components/Assign.svelte";
	import {
		AlertTriangleIcon,
		ChevronRightIcon,
		ChevronDownIcon,
		SaveIcon,
		PlusIcon,
		Trash2Icon,
		XIcon,
	} from "svelte-feather-icons";

	let loading = true;

	let saving = false;

	let showMore = false;

	let openDelete = false;

	let organizationParams = {};

	let ldapUnitMatches = [];

	let ldap = false;

	$: if ($commons && $commons.ldap) {
		ldap = true;
	} else {
		ldap = false;
	}

	const openDeleteDialog = function () {
		openDelete = true;
	};

	function cancelDeleteDialog() {
		openDelete = false;
	}

	const addNewMatch = function () {
		ldapUnitMatches = [
			...ldapUnitMatches,
			{
				ldapOu: "",
				chart: null,
				unit: null,
				role: null,
			},
		];
	};

	const removeMatch = function (match) {
		let index = ldapUnitMatches.indexOf(match);
		if (index !== -1) {
			ldapUnitMatches.splice(index, 1);
			ldapUnitMatches = ldapUnitMatches;
		}
	};

	const deleteOrganizationParams = async function () {
		// /organizationParams/{clientUuid}/delete

		cancelDeleteDialog();

		if ($commons && $commons.orgUuid) {
			try {
				const res = await $axiosAuth.delete(
					"/organizationParams/" + $commons.orgUuid + "/delete"
				);
				window.location.reload();
			} catch (error) {
				throw new Error(error);
				console.log(error);
				console.log(res);
			}
		}
	};

	const getOrganizationParams = async function () {
		try {
			// /{clientUuid}/person
			const res = await $axiosAuth.get(
				"/organizationParams/" + $commons.orgUuid + "/retrieve"
			);
			organizationParams = await res.data;

			if (!organizationParams.ldapSettingsDTO) {
				organizationParams.ldapSettingsDTO = {
					initialContextFactory: "com.sun.jndi.ldap.LdapCtxFactory",
					ctxSearchName: "",
					debugMode: true,
					emailLocation: "mail",
					fullNameLocation: "CN",
					ldapReadingPeekDuration: {
						days: 0,
						hours: 0,
						minutes: 0,
						seconds: 0,
					},
					ldapUnitMatches: [],
					providerUrl: "",
					referral: "follow",
					running: false,
					sAMAccountNameLocation: "sAMAccountName",
					searchStringQuery: "",
					securityAuthentication: "simple",
					securityCredentials: "",
					securityPrincipal: "PersePhoneBind",
					ssoAuthorityIds: "",
				};
			}

			if (organizationParams.ldapSettingsDTO.ldapUnitMatches) {
				ldapUnitMatches =
					organizationParams.ldapSettingsDTO.ldapUnitMatches.map(
						function (x) {
							return {
								...x,
								chart: null,
								unit: null,
								role: null,
							};
						}
					);
			}

			loading = false;
		} catch (error) {
			throw new Error(error);
			console.log(error);
			console.log(res);
		}
	};

	const updateOrganizationParams = async function () {
		if ($commons && $commons.orgUuid) {
			saving = true;

			organizationParams.ldapSettingsDTO.ldapUnitMatches =
				ldapUnitMatches.map(function (m) {
					let j = {
						ldapOu: m.ldapOu,
						orgChartId:
							m.chart && m.chart.uuid ? m.chart.uuid : null,
						orgUnitId: m.unit && m.unit.uuid ? m.unit.uuid : null,
						orgRoleId: m.role && m.role.uuid ? m.role.uuid : null,
					};
					return j;
				});

			try {
				const res = await $axiosAuth.post(
					"/organizationParams/" + $commons.orgUuid + "/update",
					{
						ldapSettingsDTO: organizationParams.ldapSettingsDTO,
					}
				);
				toast.push("Επιτυχία αποθήκευσης");
			} catch (error) {
				toast.push("Σφάλμα κατά την αποθήκευση");
				throw new Error(error);
				console.log(error);
				console.log(res);
			} finally {
				saving = false;
			}
		}
	};

	const startLDAPPeeking = async function () {
		if ($commons && $commons.orgUuid) {
			try {
				const res = await $axiosAuth.get(
					"/organizationParams/" + $commons.orgUuid + "/ldap/start"
				);
				organizationParams.ldapSettingsDTO.running = true;
				toast.push("Επιτυχία εκκίνησης παρακολούθησης");
			} catch (error) {
				toast.push("Σφάλμα κατά την εκκίνηση παρακολούθησης");
				throw new Error(error);
				console.log(error);
				console.log(res);
			}
		}
	};

	const stopLDAPPeeking = async function () {
		if ($commons && $commons.orgUuid) {
			try {
				const res = await $axiosAuth.get(
					"/organizationParams/" + $commons.orgUuid + "/ldap/stop"
				);
				organizationParams.ldapSettingsDTO.running = false;
				toast.push("Επιτυχία παύσης παρακολούθησης");
			} catch (error) {
				toast.push("Σφάλμα κατά την παύση παρακολούθησης");
				throw new Error(error);
				console.log(error);
				console.log(res);
			}
		}
	};

	const toggleRunning = function () {
		if (organizationParams.ldapSettingsDTO.running) {
			stopLDAPPeeking();
		} else {
			startLDAPPeeking();
		}
	};

	$: if ($commons && $commons.orgUuid) {
		getOrganizationParams();
	}

	// ldapSettingsDTO

	// 	private String initialContextFactory = "com.sun.jndi.ldap.LdapCtxFactory";
	// private String providerUrl;
	// private String referral = "follow";
	// private String securityAuthentication = "simple";
	// private String securityPrincipal = "PersePhoneBind";
	// private String securityCredentials;
	// private String searchStringQuery;
	// private String ctxSearchName;
	// private String emailLocation = "mail";
	// private String sAMAccountNameLocation = "sAMAccountName";
	// private String fullNameLocation = "CN";
	// //separated by comma
	// private String ssoAuthorityIds;
	// private boolean debugMode = true;
	// private TimePeriodDefinition ldapReadingPeekDuration = new TimePeriodDefinition(0, 0, 30, 0);
	// private Set < LdapRoleDTO > ldapRoleDTO = new HashSet <> ();
</script>

{#if ldap}
	<div class="title">
		Ρυθμίσεις
		<!-- Settings -->
	</div>

	{#if organizationParams.ldapSettingsDTO}
		<div in:fade>
			<div class="column is-full">
				<div class="columns is-vcentered">
					<div class="column is-narrow">
						<div class="subtitle is-3">Παρακολούθηση LDAP</div>
					</div>

					<div
						class="column is-narrow"
						title={organizationParams.ldapSettingsDTO.running
							? "Ενεργό"
							: "Ανενεργό"}
					>
						<Switch
							on:change={toggleRunning}
							onColor="#48c774"
							checked={organizationParams.ldapSettingsDTO.running}
						/>
					</div>
				</div>

				<div
					class="column"
					class:notification={organizationParams.ldapSettingsDTO
						.debugMode}
					class:my-warning-light={organizationParams.ldapSettingsDTO
						.debugMode}
				>
					{#if organizationParams.ldapSettingsDTO.debugMode}
						<div class="columns">
							<div class="column is-narrow">
								<AlertTriangleIcon size="24" />
							</div>
							<div class="column">
								Η λειτουργία εντοπισμού σφαλμάτων είναι
								επιλεγμένη. <br> Η μεταφορά δεδομένων LDAP
								καταγράφεται αλλά ΔΕΝ πραγματοποιείται.
							</div>
						</div>
					{/if}
					<label class="checkbox">
						<input
							bind:checked={organizationParams.ldapSettingsDTO
								.debugMode}
							type="checkbox"
							placeholder=""
						/>
						Λειτουργία εντοπισμού σφαλμάτων (debug mode)
					</label>
				</div>
			</div>

			<hr />

			<div class="field is-horizontal">
				<div class="field-label is-normal">
					<label class="label"> Διεύθυνση LDAP (provider URL) </label>
				</div>
				<div class="field-body">
					<div class="field">
						<div class="control">
							<input
								bind:value={organizationParams.ldapSettingsDTO
									.providerUrl}
								class="input"
								type="text"
								placeholder=""
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="field is-horizontal">
				<div class="field-label is-normal">
					<label class="label">
						Διαπιστευτήριο ασφαλείας (security credentials)
					</label>
				</div>
				<div class="field-body">
					<div class="field">
						<div class="control">
							<input
								bind:value={organizationParams.ldapSettingsDTO
									.securityCredentials}
								class="input"
								type="text"
								placeholder=""
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="field is-horizontal">
				<div class="field-label is-normal">
					<label class="label">
						Λογαριασμός διεύθυνσης ασφαλείας (security principal)
					</label>
				</div>
				<div class="field-body">
					<div class="field">
						<div class="control">
							<input
								bind:value={organizationParams.ldapSettingsDTO
									.securityPrincipal}
								class="input"
								type="text"
								placeholder=""
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="field is-horizontal">
				<div class="field-label is-normal">
					<label class="label">
						Ονομασία ριζικού φακέλου προς αναζήτηση (search name)
					</label>
				</div>
				<div class="field-body">
					<div class="field">
						<div class="control">
							<input
								bind:value={organizationParams.ldapSettingsDTO
									.ctxSearchName}
								class="input"
								type="text"
								placeholder=""
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="field is-horizontal">
				<div class="field-label is-normal">
					<label class="label">
						Ερώτημα αναζήτησης χρηστών (search query)
					</label>
				</div>
				<div class="field-body">
					<div class="field">
						<div class="control">
							<textarea
								bind:value={organizationParams.ldapSettingsDTO
									.searchStringQuery}
								class="textarea"
								placeholder=""
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="field is-horizontal">
				<div class="field-label is-normal">
					<label class="label">
						Δικαιώματα SSO (Αύξοντες αριθμοί) (authority ids)
					</label>
				</div>
				<div class="field-body">
					<div class="field">
						<div class="control">
							<input
								bind:value={organizationParams.ldapSettingsDTO
									.ssoAuthorityIds}
								class="input"
								type="text"
								placeholder=""
							/>
						</div>
					</div>
				</div>
			</div>

			<hr />

			<!-- peek duration -->
			<div class="subtitle is-4">Επαναληψιμότητα λειτουργίας</div>

			<!-- ldapReadingPeekDuration":{"days":0,"hours":0,"minutes":0,"seconds":5,"duration":"PT5S"} -->

			{#if organizationParams.ldapSettingsDTO.ldapReadingPeekDuration}
				<div class="field is-horizontal">
					<div class="field-label is-normal">
						<label class="label">
							Ημέρες
							<!-- Days -->
						</label>
					</div>
					<div class="field-body">
						<div class="field">
							<div class="control">
								<input
									bind:value={organizationParams
										.ldapSettingsDTO.ldapReadingPeekDuration
										.days}
									class="input"
									type="number"
									placeholder=""
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="field is-horizontal">
					<div class="field-label is-normal">
						<label class="label">
							Ώρες
							<!-- Hours -->
						</label>
					</div>
					<div class="field-body">
						<div class="field">
							<div class="control">
								<input
									bind:value={organizationParams
										.ldapSettingsDTO.ldapReadingPeekDuration
										.hours}
									class="input"
									type="number"
									placeholder=""
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="field is-horizontal">
					<div class="field-label is-normal">
						<label class="label">
							Λεπτά
							<!-- Minutes -->
						</label>
					</div>
					<div class="field-body">
						<div class="field">
							<div class="control">
								<input
									bind:value={organizationParams
										.ldapSettingsDTO.ldapReadingPeekDuration
										.minutes}
									class="input"
									type="number"
									placeholder=""
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="field is-horizontal">
					<div class="field-label is-normal">
						<label class="label">
							Δευτερόλεπτα
							<!-- Seconds -->
						</label>
					</div>
					<div class="field-body">
						<div class="field">
							<div class="control">
								<input
									bind:value={organizationParams
										.ldapSettingsDTO.ldapReadingPeekDuration
										.seconds}
									class="input"
									type="number"
									placeholder=""
								/>
							</div>
						</div>
					</div>
				</div>
			{/if}

			<hr />

			<!-- peek duration -->
			<div class="subtitle is-4">
				Αντιστοίχιση οργανωτικών μονάδων LDAP
			</div>

			{#each ldapUnitMatches as match}
				<div class="columns is-vcentered is-mobile">
					<div class="column columns is-vcentered">
						<div class="column">
							<div class="control">
								<label class="label">
									Οργανωτική μονάδα LDAP
								</label>
								<div class="columns is-vcentered">
									<div class="column">
										<input
											bind:value={match.ldapOu}
											class="input"
											type="string"
											placeholder=""
										/>
									</div>
									<div
										class="column is-narrow is-hidden-mobile"
									>
										<ChevronRightIcon size="24" />
									</div>
									<!-- when columns become rows: -->
									<div
										class="column is-narrow is-hidden-tablet has-text-centered"
									>
										<ChevronDownIcon size="24" />
									</div>
								</div>
							</div>
						</div>
						{#if !loading}
							<div class="column columns is-vcentered is-mobile">
								<div class="column">
									<label class="label">
										Οργανόγραμμα / Οργανωτική μονάδα / Ρόλος
									</label>

									<div class="row">
										<div class="control">
											<Assign
												type="chart"
												bind:selectedItem={match.chart}
												initUuid={match.orgChartId}
											/>
										</div>
									</div>
									{#if match.chart}
										<div class="row">
											<div class="control">
												<Assign
													type="unit"
													bind:selectedItem={match.unit}
													initUuid={match.orgUnitId}
													bind:selectedChart={match.chart}
												/>
											</div>
										</div>
										<div class="row">
											<div class="control">
												<Assign
													type="role"
													bind:selectedItem={match.role}
													initUuid={match.orgRoleId}
													bind:selectedChart={match.chart}
												/>
											</div>
										</div>
										<!-- 
					{#if !match.unit || !match.role}
					<div class="has-text-danger">Χρειάζεται να συμπληρωθούν και η Οργανωτική μονάδα και ο Ρόλος</div>
					{/if} -->
									{/if}
								</div>
							</div>
						{/if}
					</div>
					<div class="column is-narrow">
						<button on:click={removeMatch(match)} class="button">
							<span class="icon is-small">
								<XIcon size="24" />
							</span>
						</button>
					</div>
				</div>

				<hr />
			{/each}

			<button class="button" on:click={addNewMatch}>
				<PlusIcon size="24" />
			</button>

			<hr />
			<label class="checkbox">
				<input bind:checked={showMore} type="checkbox" placeholder="" />
				Εμφάνιση περισσότερων ρυθμίσεων
			</label>

			{#if showMore}
				<hr />
				<div class="block">
					<div class="field is-horizontal">
						<div class="field-label is-normal">
							<label class="label">
								Initial Context Factory
							</label>
						</div>
						<div class="field-body">
							<div class="field">
								<div class="control">
									<input
										bind:value={organizationParams
											.ldapSettingsDTO
											.initialContextFactory}
										class="input"
										type="string"
										placeholder=""
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="field is-horizontal">
						<div class="field-label is-normal">
							<label class="label"> Referral </label>
						</div>
						<div class="field-body">
							<div class="field">
								<div class="control">
									<input
										bind:value={organizationParams
											.ldapSettingsDTO.referral}
										class="input"
										type="string"
										placeholder=""
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="field is-horizontal">
						<div class="field-label is-normal">
							<label class="label">
								Security authentication
							</label>
						</div>
						<div class="field-body">
							<div class="field">
								<div class="control">
									<input
										bind:value={organizationParams
											.ldapSettingsDTO
											.securityAuthentication}
										class="input"
										type="string"
										placeholder=""
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="field is-horizontal">
						<div class="field-label is-normal">
							<label class="label"> Email location </label>
						</div>
						<div class="field-body">
							<div class="field">
								<div class="control">
									<input
										bind:value={organizationParams
											.ldapSettingsDTO.emailLocation}
										class="input"
										type="string"
										placeholder=""
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="field is-horizontal">
						<div class="field-label is-normal">
							<label class="label">
								SAM-Account Name location
							</label>
						</div>
						<div class="field-body">
							<div class="field">
								<div class="control">
									<input
										bind:value={organizationParams
											.ldapSettingsDTO
											.sAMAccountNameLocation}
										class="input"
										type="string"
										placeholder=""
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="field is-horizontal">
						<div class="field-label is-normal">
							<label class="label"> Full Name location </label>
						</div>
						<div class="field-body">
							<div class="field">
								<div class="control">
									<input
										bind:value={organizationParams
											.ldapSettingsDTO.fullNameLocation}
										class="input"
										type="string"
										placeholder=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			{/if}

			<hr />

			<!-- 
	<div style="word-break: break-all;">
		{JSON.stringify(organizationParams.ldapSettingsDTO)}
	</div>  -->
		</div>
	{:else}
		<div in:fade|local class="columns" style="padding:2rem">
			<div style="margin-top:5rem">
				<Spinner size="40" speed="750" thickness="2" gap="40" />
			</div>
		</div>
	{/if}

	<div class="sticky-bottom white-fade">
		<div class="level is-mobile">
			<div class="is-hidden-touch">&nbsp;</div>
			<button
				on:click={updateOrganizationParams}
				class="button is-link"
				class:is-loading={saving}
			>
				<SaveIcon size="1.5x" />
				&nbsp; Αποθήκευση
			</button>

			<div class="level-right is-mobile">
				<button
					on:click={openDeleteDialog}
					class="button"
					class:is-loading={saving}
				>
					<Trash2Icon size="1.5x" />
					&nbsp; Διαγραφή
				</button>
			</div>

			<div class="is-hidden-touch">&nbsp;</div>
			<div class="is-hidden-touch">&nbsp;</div>
			<div class="is-hidden-touch">&nbsp;</div>
		</div>
	</div>

	<!-- delete dialog -->
	{#if openDelete}
		<div class="modal" class:is-active={openDelete}>
			<div
				out:fade|local
				on:click={cancelDeleteDialog}
				class="modal-background"
			/>
			<div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
				<header class="modal-card-head">
					<p class="modal-card-title">
						<!-- Delete -->
						Διαγραφή
					</p>
					<button
						class="delete"
						on:click={cancelDeleteDialog}
						aria-label="close"
					/>
				</header>
				<section class="modal-card-body">
					Να διαγραφούν όλες οι ρυθμίσεις;
				</section>
				<footer class="modal-card-foot">
					<button
						on:click={deleteOrganizationParams}
						class="button is-pulled-left is-danger is-light"
					>
						<Trash2Icon size="1.5x" />
						<!-- &nbsp; Delete -->
						&nbsp; Διαγραφή
					</button>
					<button
						on:click={cancelDeleteDialog}
						class="button is-light pull-right"
					>
						<!-- Cancel -->
						Ακύρωση
					</button>
				</footer>
			</div>
		</div>
	{/if}
	<!-- if ldap ends -->
{:else}
	<div class="text-center">
		<p class="is-size-3">Δεν βρέθηκε κάτι.</p>

		<p style="margin-top:2rem">
			<a href={$url("../structures")}>
				<button class="button is-link is-medium">
					Επιστροφή στην αρχή
				</button>
			</a>
		</p>
	</div>
{/if}

<style>
	.my-warning-light {
		background: #fef08a;
		color: #78350f;
	}
</style>
