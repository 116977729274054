<script>
  import AutoComplete from "simple-svelte-autocomplete";
  import { params, goto, url, isActive } from "@sveltech/routify";
  import { axiosAuth, commons } from "../auth.js";
  import { byName, byLastName } from "../utils.js";
  import { units, roles, people, groups, charts, gettingCharts } from "../corpjobperson.js";
  import { removeAccents } from "../removeaccents.js";
  import { notallowed } from "../notallowed.js";

  let items;

  async function getGroups(clientUuid) {
    try {
      // /{clientUuid}/person
      const res = await $axiosAuth.get("/v2/" + clientUuid + "/synonyms/");
      $groups = await res.data;
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    }
  }

  async function getPeople(clientUuid) {
    try {
      // /{clientUuid}/person
      const res = await $axiosAuth.get("/" + clientUuid + "/person");
      $people = await res.data.sort(byLastName);
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    }
  }

  //  /{clientUuid}/structure/{orgChartUuid}/corpjobperson
  async function getItems(clientUuid, orgChartUuid) {
    try {
      const res = await $axiosAuth.get(
        "/" + clientUuid + "/structure/" + orgChartUuid + "/corpjobperson"
      );
      $units = await res.data.responseCorporateNanoDto.sort(byName);
      $roles = await res.data.responseJobDescriptionNanoDto.sort(byName);
      // $people = await res.data.responsePersonNanoDto; // doesn't return people without assignments
      // $groups = await res.data.synonyms; //doesn't return groups without assignments

      // console.log("yes", res);
    } catch (error) {
      throw new Error(error);
      console.log(units);
      console.log(res);
    }
  }

  async function getCharts(clientUuid) {
    $gettingCharts = true;
    try {
      const res = await $axiosAuth.get("/client/" + clientUuid + "/charts");
      $charts = await res.data.structureCharts;
      // console.log("the charts are", res);
    } catch (error) {
      $notallowed = true;
      throw new Error(error);
      $charts = [];
      console.log(charts);
      console.log(res);
    } finally {
      $gettingCharts = false;
    }
  }

  let selectedItem;

  function clear() {
    selectedItem = null;
  }

  function go() {
    if (selectedItem) {
      if ($params.department) {
        $goto("/structure/:chart/department/:department", {
          chart: $params.chart,
          department: selectedItem.uuid,
        });
      } else if ($params.person) {
        $goto("/person/:person", {
          person: selectedItem.personDto.uuid,
        });
      } else if ($params.role) {
        $goto("/role/:role", {
          role: selectedItem.uuid,
        });
      } else if ($params.group) {
        $goto("/group/:group", {
          group: selectedItem.uuid.replace("SLINK->", ""),
        });
      } else {
        $goto("structure/:chart/department/:department", {
          chart: selectedItem.uuid,
          department: "all",
        });
      }
    }
    setTimeout(() => { selectedItem = null;}, 30);
    
  }

  $: {
    if ($params.department) {
      items = $units;
    } else if ($params.person) {
      items = $people;
    } else if ($params.role) {
      items = $roles;
    } else if ($params.group) {
      items = $groups;
    } else {
      items = $charts;
    }
  }

  // $: console.log("selectedItem", selectedItem);

  let gotCharts = false;

  $: if ($params && $commons.orgUuid && !gotCharts) {
    clear();
    console.log("just cleared and charts are", $charts);
    let promise2 = getCharts($commons.orgUuid);
    gotCharts = true;
  }

  // let prevChart = null;
  // guard against multiple calls for the same chart
  // $params.chart != prevChart

  $: if ($params && $charts && $charts.length) {
    let chart;
    if ($params.chart === "undefined" || !$params.chart) {
      chart = $charts[0].uuid;
    } else {
      chart = $params.chart;
    }
    // prevChart = chart;
    console.log("chart is", chart);
    console.log("charts are", $charts);
    let promise = getItems($commons.orgUuid, chart);
    let promise2 = getPeople($commons.orgUuid);
    let promise3 = getGroups($commons.orgUuid);
  }

  const labelFunction = (item) => {
    let keyword = "";
    if (item.name) {
      keyword = item.name;
    } else {
      item = item.personDto;
      if (item.lastName) {
        keyword += item.lastName + " ";
      }
      keyword += item.name;
      if (item.workEmail) {
        keyword += " " + item.workEmail;
      }
    }

    if (item.disabled) {
      keyword = "(Ανενεργό) " + keyword;
    }
    return keyword;
  };

  const keywordsFunction = (item) => {
    let label = labelFunction(item);
    return label + " " + removeAccents(label);
  };
</script>

<!-- class:is-hidden={$isActive('../structures')} -->
<AutoComplete
  {items}
  bind:selectedItem
  noResultsText="Δεν βρέθηκαν αποτελέσματα"
  onChange={go}
  keywordsFunction={labelFunction}
  {labelFunction}
  keywordsCleanFunction={removeAccents}
  textCleanFunction={removeAccents}
  placeholder="Αναζήτηση..."
/>

<!-- disable clear function to show the following -->

<!-- {JSON.stringify(selectedItem)} -->
<style>
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 3.5rem;
  }
</style>
