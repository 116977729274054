<script>
  import Switch from "svelte-switch";
  import { params, url, goto, beforeUrlChange } from "@sveltech/routify";
  import { axiosAuth, commons, profile } from "../../auth.js";
  import { byLastName, findUnitName } from "../../utils.js";
  import { charts, units, roles, people } from "../../corpjobperson.js";
  import { removeAccents, labelFunction } from "../../removeaccents.js";
  import Assign from "../../components/Assign.svelte";
  import {
    PlusIcon,
    Link2Icon,
    Trash2Icon,
    DownloadIcon,
    SaveIcon,
  } from "svelte-feather-icons";
  import { fade, scale, fly } from "svelte/transition";
  import { toast } from "@zerodevx/svelte-toast";
  import Spinner from "svelte-spinner";

  //keycloak
  const KEYCLOAK_URL = window.env ? window.env.KEYCLOAK_URL : __process.env.KEYCLOAK_URL;

  let person = { structuralUnitUuid: [], specialityUuid: [] };
  let references = [];
  let groups = [];
  let open = false;
  let openDelete = false;
  let newPersonName;
  let newPersonLastName;
  let newPersonEmail;
  let newPersonAuthUserName;
  let saving = false;
  let deleting = false;
  let removeReferences = [];
  let addReferences = [];
  let similarPeople = [];

  $: if (
    (newPersonName && newPersonName.length > 2) ||
    (newPersonLastName && newPersonLastName.length > 2) ||
    (newPersonEmail && newPersonEmail.length > 2) ||
    (newPersonAuthUserName && newPersonAuthUserName.length > 2)
  ) {
    similarPeople = $people.filter(function (p) {
      let label = removeAccents(labelFunction(p));
      let found1 = false;
      let found2 = false;
      let found3 = false;
      let found4 = false;

// KEYCLOAK_URL && 
      if (newPersonAuthUserName) {
        found4 = label.includes(removeAccents(newPersonAuthUserName));
        return found4;
      }

      if (newPersonEmail) {
        found3 = label.includes(removeAccents(newPersonEmail));
        return found3;
      }

      if (newPersonName) {
        found1 = label.includes(removeAccents(newPersonName));
      }
      if (newPersonLastName) {
        found2 = label.includes(removeAccents(newPersonLastName));
      }

      return found1 || found2;
    });
  } else {
    similarPeople = [];
  }

  $beforeUrlChange((event, store) => {
    if (addReferences.length + removeReferences.length == 0) return true;

    let cont = confirm(
      "Θέλετε να αποχωρήσετε χωρίς να αποθηκεύσετε τις τελευταίες αλλαγές;"
    );
    if (cont) {
      addReferences = [];
      removeReferences = [];
    }
    return cont;
  });

  let selectedChart;
  let selectedPerson;
  let selectedRole;
  let selectedUnit;
  let openAssign = false;

  const sortReferences = (a, b) => {
    if (a.chartName > b.chartName) return 1;
    else {
      if (a.chartName < b.chartName) {
        return -1;
      } else return 0;
    }
  };

  const openAssignDialog = () => {
    selectedPerson = person ? { personDto: person } : null;
    selectedChart = null;
    selectedRole = null;
    selectedUnit = null;
    openAssign = true;
  };

  let disableAdd = true;

  $: if (selectedChart && selectedRole && selectedUnit && selectedPerson) {
    disableAdd = false;
  } else {
    disableAdd = true;
  }

  const cancelAssign = () => {
    openAssign = false;
  };

  const pushAssign = () => {
    addReferences.push({
      person: selectedPerson,
      chart: selectedChart,
      role: selectedRole,
      unit: selectedUnit,
    });
    addReferences = addReferences;
    openAssign = false;

    //  focus save button
    setTimeout(function () {
      document.getElementById("saveBtn").focus();
    }, 0);
  };

  const removeRole = (role) => {
    person.roles.splice(person.roles.indexOf(role), 1);
    person.roles = person.roles;
  };

  const removeReference = (ref) => {
    references.splice(references.indexOf(ref), 1);
    references = references;
    removeReferences.push(ref);
  };

  const cancelAdd = (ref) => {
    addReferences.splice(addReferences.indexOf(ref), 1);
    addReferences = addReferences;
  };

  async function deletePerson() {
    deleting = true;
    try {
      const res = await $axiosAuth.delete(
        "/" +
          $commons.orgUuid +
          "/person?employmentUuid=&personUuid=" +
          $params.person
      );
    } catch (error) {
    } finally {
      deleting = false;
      openDelete = false;
      $goto("/person/all");
    }
  }

  function exportCSV() {
    let delimiter = ";";
    let csvContent = "data:text/csv;charset=utf-8;sep=" + delimiter + ",\ufeff";

    $people.forEach(function (person) {
      // {person.personDto.lastName}
      //             {person.personDto.name}
      //           </div>

      //           <div class="column">{person.personDto.workEmail}</div>

      let uuid = person.personDto.uuid;
      let name = person.personDto.name || "";
      let lastName = person.personDto.lastName || "";
      let email = person.personDto.workEmail || "";
      let userName = person.personDto.authUserName || "";
      let assignments = "";

      let length = person.personAssignments.length;
      for (let i = length - 1; i >= 0; i--) {
        let assignment = person.personAssignments[i];
        if (!assignment.disabled) {
          let assignmentString =
            assignment.jobTitle + " > " + assignment.corporateTitle;
          if ($charts && $charts.length > 1) {
            assignmentString +=
              " > " + findUnitName(assignment.orgChartUuid, $charts);
          }
          if (i == length) {
            assignments = assignmentString;
          } else {
            assignments = assignmentString + delimiter + assignments;
          }
        }
      }

      let userNameIfWithDelimiter = userName ? userName + delimiter : "";

      let row =
        uuid +
        delimiter +
        name +
        delimiter +
        lastName +
        delimiter +
        userNameIfWithDelimiter +
        email +
        delimiter +
        assignments;
      csvContent += row + "\r\n";
    });

    // csvContent += "sep=" + delimiter + "\r\n";

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      $profile.customInfo.org + "_Πρόσωπα" + ".csv"
    );
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file.
  }

  function openNewPersonModal() {
    newPersonName = null;
    newPersonLastName = null;
    newPersonEmail = null;
    newPersonAuthUserName = null;
    open = true;
  }

  function cancelDeleteDialog() {
    openDelete = false;
  }

  function cancel() {
    open = false;
  }

  async function addPerson() {
    let requestPostPerson = {
      validFrom: "",
      validUntil: "",
      personDto: {
        name: newPersonName.trim(),
        lastName: newPersonLastName.trim(),
        workEmail: newPersonEmail ? newPersonEmail.trim() : "",
        authUserName: newPersonAuthUserName ? newPersonAuthUserName.trim() : "",
      },
    };

    let result;

    try {
      const res = await $axiosAuth.post(
        "/" + clientUuid + "/person",
        requestPostPerson
      );

      result = await res.data;
    } catch (error) {
      alert(error);
    } finally {
      open = false;
      if (result) {
        $people = [...$people, result];
        $goto("/person/:person", { person: result.personDto.uuid });
      }
    }
  }

  async function savePerson() {
    if (saving) return;

    saving = true;

    let needToUpdateAssignments = false;

    if (addReferences.length) {
      needToUpdateAssignments = true;
    }

    person.structuralUnitUuid = [];
    person.jobPositionUuid = [];
    person.specialityUuid = [];

    person.name = person.name.trim();
    person.lastName = person.lastName.trim();
    person.workEmail = person.workEmail.trim();

    let requestPutPerson = {
      validFrom: "",
      validUntil: "",
      personDto: person,
    };

    let result;

    try {
      // let addPromises = addReferences.map((ref) => {
      //   return $axiosAuth.put(
      //     "/v2/" +
      //       clientUuid +
      //       "/assignments/charts/" +
      //       ref.chart.uuid +
      //       "/unit/" +
      //       ref.unit.uuid +
      //       "/job/" +
      //       ref.role.uuid +
      //       "/person/" +
      //       ref.person.personDto.uuid,
      //     {
      //       clientUuid: clientUuid,
      //       corporateUuid: ref.unit.uuid,
      //       jobDescriptionUuid: ref.role.uuid,
      //       orgChartUuid: ref.chart.uuid,
      //       personUuid: ref.person.personDto.uuid,
      //     }
      //   );
      // });



      // let removePromises = removeReferences.map((ref) => {
      //   return $axiosAuth.delete(
      //     "/v2/" + clientUuid + "/assignments/" + ref.assignmentId
      //   );
      // });



      //1. first remove assignments that need removing
      for (let i = 0; i < removeReferences.length; i++) {
        let ref = removeReferences[i];
        if (ref && ref.assignmentId) {
          await $axiosAuth.delete(
            "/v2/" + clientUuid + "/assignments/" + ref.assignmentId
          );
        }
      }

      let res = [];

      //2. then add new assignments
      for (let i = 0; i < addReferences.length; i++) {
        let ref = addReferences[i];
        if (ref && ref.chart && ref.chart.uuid) {
          let tmp = await $axiosAuth.put(
            "/v2/" +
              clientUuid +
              "/assignments/charts/" +
              ref.chart.uuid +
              "/unit/" +
              ref.unit.uuid +
              "/job/" +
              ref.role.uuid +
              "/person/" +
              ref.person.personDto.uuid,
            {
              clientUuid: clientUuid,
              corporateUuid: ref.unit.uuid,
              jobDescriptionUuid: ref.role.uuid,
              orgChartUuid: ref.chart.uuid,
              personUuid: ref.person.personDto.uuid,
            }
          );
          res.push(tmp);
        }
      }

      //1. first remove assignments that need removing
      // const firstRemove = await Promise.all(removePromises);

      //2. then add new assignments
      // const res = await Promise.all(addPromises);

      //3. finally save the person in case it has been edited
      const thenSave = await $axiosAuth
        .put(
          "/" + clientUuid + "/person?recursivePositionDelete=false",
          requestPutPerson
        )
        .then(() => toast.push("Αποθηκεύτηκε"));

      removeReferences = [];
      addReferences = [];

      if (needToUpdateAssignments) {
        let newreferences = res.filter((r) => r.data.assignmentId);
        if (newreferences.length) {
          references = references
            .concat(
              newreferences.map((r) => {
                let chart = $charts.find((c) => c.uuid === r.data.orgChartUuid);
                r.data.chartName = chart.name;
                return r.data;
              })
            )
            .sort(sortReferences);
        }
      }

      // console.log("this is it", res);
    } catch (error) {
      alert(error);
    } finally {
      setTimeout(() => (saving = false), 400);
    }
  }

  $: console.log("person is", person);

  function getUnitName(uuid) {
    let found = $units.filter((unit) => uuid === unit.uuid);
    if (found && found.length) {
      console.log("got herer", found);
      return found[0].name;
    } else {
      return "No departments in this chart";
    }
  }

  function getRoleName(uuid) {
    let found = $roles.filter((unit) => uuid === unit.uuid);
    if (found && found.length) {
      return found[0].name;
    } else {
      return "Unknown " + uuid;
    }
  }

  // $: console.log("people", $people);

  async function getPeople(clientUuid) {
    try {
      // /{clientUuid}/person
      const res = await $axiosAuth.get("/" + clientUuid + "/person");
      $people = await res.data.sort(byLastName);
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    }
  }

  async function getGroups(clientUuid) {
    try {
      const res = await $axiosAuth.get(
        "/v2/" + clientUuid + "/synonyms/person/" + $params.person
      );
      groups = res.data;
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  }

  async function getReferences(clientUuid) {
    try {
      let promises = $charts.map((chart) =>
        $axiosAuth.get(
          "/" +
            clientUuid +
            "/" +
            chart.uuid +
            "/person/" +
            $params.person +
            "/references"
        )
      );
      const res = await Promise.all(promises);
      references = res.reduce((acc, r) => acc.concat(r.data), []);
      references = references
        .map((r) => {
          let chart = $charts.find((c) => c.uuid === r.orgChartUuid);
          r.chartName = chart.name;
          if (r.pathToUnit) {
            r.pathToUnit.reverse();
            r.pathToUnit.pop();
          }
          return r;
        })
        .sort(sortReferences);
      console.log("mine", references);
      return res;
    } catch (error) {
      alert(JSON.stringify(error));
    }
  }

  async function getPerson(clientUuid) {
    open = false; //when the user clicks on a suggestion that links to a person from the open dialog, we need the dialog to close
    try {
      const res = await $axiosAuth.get(
        "/" + clientUuid + "/person?personUuid=" + $params.person
      );
      person = await res.data[0].personDto;
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    } finally {
      loadingPerson = false;
    }
  }

  let clientUuid = 0;
  let loadingPerson = false;

  let personGuard = null;
  // ensures that the following reactive statement doesn't get executed twice when the person param changes

  const toggleActive = async function () {
    if (person.active === "true") {
      person.active = "false";
    } else {
      person.active = "true";
    }
    await savePerson();

    let clientUuid = $commons.orgUuid;
    await getReferences(clientUuid);
    await getGroups(clientUuid);
  };

  $: if (
    $commons &&
    $commons.orgUuid &&
    $params &&
    $params.person &&
    $params.person != personGuard &&
    $charts &&
    $charts.length
  ) {
    personGuard = $params.person;
    clientUuid = $commons.orgUuid;
    // let promise0 = getPeople(clientUuid); //not needed, people are fetched from the search component
    if ($params.person != "all") {
      loadingPerson = true;
      Promise.all([getReferences(clientUuid), getGroups(clientUuid)]).then(
        getPerson(clientUuid)
      );
    }
  }
</script>

<div class="columns columns-reverse-mobile">
  {#if $params.person == "all"}
    <div class="column column-reverse-mobile menu">
      <div class="level">
        <div class="title level-left">
          <div in:fade class="level-item">
            Πρόσωπα {#if $people && $people.length}({$people.length}){/if}
          </div>
          <div class="level-item">
            <button
              on:click={exportCSV}
              class="button"
              title="Αποθήκευση ως CSV"
            >
              <DownloadIcon size="1.5x" />
            </button>
          </div>
        </div>
      </div>
      {#if $people && $people.length}
        <ul class="menu-list">
          {#each $people as person}
            <li in:fade>
              <a
                href={$url("/person/:person", {
                  person: person.personDto.uuid,
                })}
                class="bordered-bottom"
              >
                <div class="columns">
                  <div class="column">
                    {person.personDto.lastName}
                    {person.personDto.name}
                  </div>

                  <div class="column">{person.personDto.workEmail || ''}</div>

                  <div class="column">{person.personDto.authUserName || ''}</div>

                  <div class="column">
                    {#if person.personAssignments && person.personAssignments.length}
                      {#each person.personAssignments as assignment}
                        <div
                          class="block"
                          class:line-through={assignment.disabled}
                        >
                          {assignment.jobTitle} ›
                          {assignment.corporateTitle} ›
                          {#if $charts && $charts.length > 1}
                            {findUnitName(assignment.orgChartUuid, $charts)}
                          {/if}
                          <!-- <div style="word-break: break-all;">
          {JSON.stringify(assignment)}
        </div> -->
                        </div>
                      {/each}
                    {/if}
                  </div>
                </div>
              </a>
            </li>
          {/each}
        </ul>
      {:else if !$people}
        <div in:fade|local class="columns" style="padding:2rem">
          <div style="margin-top:5rem">
            <Spinner size="40" speed="750" thickness="2" gap="40" />
          </div>
        </div>
      {/if}

      <div class="sticky-bottom white-fade">
        <button
          class="button is-link sticky-bottom"
          on:click={openNewPersonModal}
        >
          <PlusIcon size="1.5x" />
          &nbsp; Νέο πρόσωπο
        </button>
      </div>
    </div>
  {/if}

  <!-- {#if $units.length && $roles.length} -->
  {#if true}
    <!-- -->
    {#if $params.person != "all" && person && !loadingPerson}
      <div class="column column-reverse-mobile is-full" in:fade|local>
        <div class="">
          <div class="columns is-vcentered">
            <div class="column is-narrow">
              <div class="title">
                {#if person.lastName}{person.lastName}{/if}
                {person.name}
              </div>
            </div>

            <!-- this is is used to play nice with older backend versions which don't use the 'active' field -->
            <div class="column is-narrow">
              {#if person.active}
                <div
                  class="field"
                  title={person.active ? "Ενεργό" : "Ανενεργό"}
                >
                  <Switch
                    on:change={toggleActive}
                    onColor="#48c774"
                    checked={person.active === "true"}
                  />
                </div>
              {/if}
            </div>
          </div>

          <!-- <div style="word-wrap: break-word; padding:3rem">
        {JSON.stringify(person)}
      </div> -->

          <div class:disabled-unit={person.active && person.active != "true"}>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">
                  <!-- Last Name -->
                  Επώνυμο
                </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      bind:value={person.lastName}
                      class="input"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">
                  <!-- Name -->
                  Όνομα
                </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      bind:value={person.name}
                      class="input"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">e-mail</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      bind:value={person.workEmail}
                      class="input"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- {#if KEYCLOAK_URL} -->
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">username</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input
                        bind:value={person.authUserName}
                        class="input"
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            <!-- {/if} -->

              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Κωδικός διασύνδεσης με τρίτο σύστημα</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input
                        bind:value={person.employeeId}
                        class="input"
                        type="text"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

            <!-- 
                {#if $units.length}
                <label class="label">Units</label>
                <div class="field is-grouped is-grouped-multiline">
                  {#each person.structuralUnitUuid as id}
                    <div class="control">
                      <div class="tags has-addons">
                        <a
                          class="tag is-medium is-link is-light"
                          href={$url(
                            '/structure/:chart/department/:department',
                            {
                              chart: $params.chart,
                              department: id
                            }
                          )}>
                          {getUnitName(id)}
                        </a>
                        <a class="tag is-medium is-delete is-link is-light" />
                      </div>
                    </div>
                  {/each}
                </div>
                {/if} -->

            <hr />

            {#if addReferences && addReferences.length}
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">
                    <!-- References -->
                    <!-- Νέες αναθέσεις -->
                  </label>
                </div>

                <div class="field-body">
                  <div class="field">
                    {#each addReferences as ref}
                      <!-- {JSON.stringify(ref)} -->
                      <div style="margin-top:.5rem">{ref.chart.name}</div>
                      <div class="tags has-addons">
                        <a class="tag is-medium is-light">{ref.unit.name}</a>
                        <a class="tag is-medium is-light">{ref.role.name}</a>
                        <a
                          on:click={cancelAdd(ref)}
                          class="tag is-medium is-delete is-light"
                        />
                      </div>
                    {/each}
                  </div>
                </div>
              </div>
            {/if}

            <!-- References -->
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label"
                  >{references && references.length > 1
                    ? "Αναθέσεις"
                    : "Ανάθεση"}</label
                >
              </div>

              <div class="field-body">
                <div class="field">
                  {#each references as ref, i}
                    {#if i == 0 || references[i - 1].chartName != ref.chartName}
                      <div style="margin-top:.5rem">{ref.chartName}</div>
                    {/if}

                    <div class="block">
                      {#if ref.pathToUnit && ref.pathToUnit.length}
                        <div class="tags">
                          {#each ref.pathToUnit.reverse() as p}
                            <a
                              class="tag is-light"
                              class:is-link={!ref.disabled}
                              href={$url(
                                "/structure/:chart/department/:department",
                                {
                                  chart: ref.orgChartUuid,
                                  department: p.uuid,
                                }
                              )}
                            >
                              {p.title}
                            </a>
                          {/each}
                        </div>
                      {/if}

                      <div class="tags has-addons">
                        <a
                          class="tag is-medium is-light"
                          class:is-link={!ref.disabled}
                          href={$url(
                            "/structure/:chart/department/:department",
                            {
                              chart: ref.orgChartUuid,
                              department: ref.corporateUuid,
                            }
                          )}
                        >
                          {#if ref.disabled}(Ανενεργό){/if}
                          {ref.corporateTitle}
                        </a>
                        <a
                          class="tag is-medium is-light"
                          class:is-success={!ref.disabled}
                          href={$url("/role/:role", {
                            role: ref.jobDescriptionUuid,
                          })}
                        >
                          {ref.jobTitle}
                        </a>
                        <a
                          on:click={removeReference(ref)}
                          class="tag is-medium is-delete is-light"
                          class:is-success={!ref.disabled}
                        />
                      </div>
                    </div>
                  {/each}
                  <!-- <div style="word-wrap:break-word">
                  {JSON.stringify(references)}
                </div> -->
                  <!-- 
                {#each $charts as chart}
                  <References
                    on:remove={removeReference}
                    name={chart.name}
                    clientuuid={clientUuid}
                    chartuuid={chart.uuid}
                    personuuid={person.uuid} />
                {/each} -->
                </div>
              </div>
            </div>

            <!-- groups -->
            {#if groups && groups.length}
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Ομάδες</label>
                </div>

                <div class="field-body">
                  <div class="field">
                    {#each groups as g, i}
                      <div class="tags">
                        <a
                          class="tag is-medium is-light is-warning"
                          href={$url("/group/:group", {
                            group: g.uuid.replace("SLINK->", ""),
                          })}
                        >
                          {g.name}
                        </a>
                      </div>
                    {/each}
                  </div>
                </div>
              </div>
            {/if}
          </div>
        </div>

        {#if $roles.length}
          <!-- Σημείωση: δεν υπάρχει προς το παρόν τρόπος να διαγράψουμε ρόλο από πρόσωπο εκτός αν διαγράψουμε το πρόσωπο. Η update person, δεν αλλάζει τους ρόλους -->
          <hr />
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Ιστορικό ρόλων</label>
            </div>
            <div class="field-body">
              <div class="field is-grouped is-grouped-multiline">
                {#if person.roles && Array.isArray(person.roles)}
                  {#each person.roles as id}
                    <div class="control">
                      <div class="tags has-addons">
                        <a
                          class="tag is-medium is-success is-light"
                          href={$url("/role/:role", { role: id })}
                        >
                          {getRoleName(id)}
                        </a>
                        <!-- the following unfortunately doesn't work, after requesting the person again: the roles haven't changed -->
                        <!-- <a
                            on:click={removeRole(id)}
                            class="tag is-medium is-delete is-light"
                          /> -->
                      </div>
                    </div>
                  {/each}
                {/if}
                <!-- debug -->
                <!-- <div style="word-wrap:break-word; width: 500px">
                  {JSON.stringify(person)}
                </div> -->
              </div>
            </div>
          </div>
        {/if}

        <hr />

        <div class="sticky-bottom white-fade">
          <div class="level is-mobile">
            <div class="field">
              <div class="field-label is-normal">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>
            </div>
            <button
              id="saveBtn"
              class="button is-pulled-right is-link"
              on:click={savePerson}
              class:is-loading={saving}
            >
              <SaveIcon size="1.5x" />
              <span class="is-hidden-mobile">&nbsp; Αποθήκευση</span>
            </button>

            <button on:click={openAssignDialog} class="button" on:click>
              <PlusIcon size="1.5x" />
              <span class="is-hidden-mobile">&nbsp; Νέα ανάθεση</span>
            </button>

            <div>&nbsp;</div>

            <button
              on:click={() => (openDelete = true)}
              class="button is-pulled-left"
            >
              <Trash2Icon size="1.5x" />
              <span class="is-hidden-mobile">&nbsp; Διαγραφή</span>
            </button>

            <button class="button" on:click={openNewPersonModal}>
              <PlusIcon size="1.5x" />
              &nbsp; Νέο πρόσωπο
            </button>
          </div>
        </div>
      </div>
    {/if}
  {/if}
</div>

<!-- <div class="box container" style="word-wrap: break-word">
  {JSON.stringify(person)}
</div> -->

<!-- delete dialog -->
{#if openDelete}
  <div class="modal" class:is-active={openDelete}>
    <div
      out:fade|local
      on:click={cancelDeleteDialog}
      class="modal-background"
    />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <!-- Delete -->
          Διαγραφή
        </p>
        <button
          class="delete"
          on:click={cancelDeleteDialog}
          aria-label="close"
        />
      </header>
      <section class="modal-card-body">
        Διαγραφή
        {person.lastName}
        {person.name}
        ;
      </section>
      <footer class="modal-card-foot">
        <button
          on:click={deletePerson}
          class:is-loading={deleting}
          class="button is-pulled-left is-danger is-light"
        >
          <Trash2Icon size="1.5x" />
          <!-- &nbsp; Delete -->
          &nbsp; Διαγραφή
        </button>
        <button
          on:click={cancelDeleteDialog}
          class="button is-light pull-right"
        >
          <!-- Cancel -->
          Ακύρωση
        </button>
      </footer>
    </div>
  </div>
{/if}

<!-- assign dialog -->
{#if openAssign}
  <div class="modal" class:is-active={openAssign}>
    <div out:fade|local on:click={cancelAssign} class="modal-background" />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Νέα ανάθεση</p>
        <button class="delete" on:click={cancelAssign} aria-label="close" />
      </header>
      <section class="modal-card-body">
        <!-- <div class="field">
          <label class="label">Πρόσωπο</label>
          <div class="control">
            <Assign type="person" bind:selectedItem={selectedPerson} />
          </div>
        </div> -->

        <div class="field">
          <label class="label">Οργανόγραμμα</label>
          <div class="control">
            <Assign type="chart" bind:selectedItem={selectedChart} />
          </div>
        </div>

        <!-- {JSON.stringify(selectedPerson)} -->
        <div class="field">
          <label class="label">Τμήμα</label>
          <div class="control">
            <Assign
              type="unit"
              bind:selectedItem={selectedUnit}
              bind:selectedChart
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Ρόλος</label>
          <div class="control">
            <Assign type="role" bind:selectedItem={selectedRole} />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          disabled={disableAdd}
          on:click={pushAssign}
          class="button is-pulled-left is-link"
        >
          &nbsp; Προσθήκη
        </button>
        <button on:click={cancelAssign} class="button is-light pull-right">
          <!-- Cancel -->
          Ακύρωση
        </button>
      </footer>
    </div>
  </div>
{/if}

<!-- add person modal -->
{#if open}
  <div class="modal" class:is-active={open}>
    <div out:fade|local on:click={cancel} class="modal-background" />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card" style="padding:0;">
      <header class="modal-card-head">
        <p class="modal-card-title">Νέο πρόσωπο</p>
        <button class="delete" on:click={cancel} aria-label="close" />
      </header>
      <section
        class="modal-card-body"
        style="overflow-y:scroll; overflow-x:hidden;"
      >
        <div class="field">
          <label class="label">Επώνυμο</label>
          <div class="control">
            <input
              type="text"
              bind:value={newPersonLastName}
              placeholder=""
              autofocus
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Όνομα</label>
          <div class="control">
            <input
              type="text"
              bind:value={newPersonName}
              placeholder=""
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">e-mail</label>
          <div class="control">
            <input
              type="text"
              bind:value={newPersonEmail}
              placeholder=""
              class="input"
            />
          </div>
        </div>

        <!-- {#if KEYCLOAK_URL} -->
          <div class="field">
            <label class="label">username</label>
            <div class="control">
              <input
                type="text"
                bind:value={newPersonAuthUserName}
                placeholder=""
                class="input"
              />
            </div>
          </div>
        <!-- {/if} -->

        <div
          style="height: {similarPeople.length
            ? 10
            : 5}rem; transition: all ease 1s;"
        >
          {#if similarPeople.length}
            <hr />
            <label for="" class="label"> Παρόμοια πρόσωπα </label>
            {#each similarPeople as p, i}
              <div style="padding-top:.5rem">
                <a
                  class="tag is-link is-light is-medium"
                  href={$url("/person/:person", { person: p.personDto.uuid })}
                >
                  {#if p.personDto.lastName}
                    {p.personDto.lastName}
                  {/if}
                  {#if p.personDto.name}
                    {p.personDto.name}
                  {/if}
                  {#if p.personDto.workEmail}
                    {p.personDto.workEmail}
                  {/if}
                  {#if p.personDto.authUserName}
                    {p.personDto.authUserName}
                  {/if}
                </a>
              </div>
            {/each}
            <div style="margin-top:1rem">&nbsp;</div>
          {/if}
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          disabled={!newPersonName}
          on:click={addPerson}
          class="button is-pulled-left is-link"
        >
          <!-- <CheckIcon size="1.5x" /> -->
          &nbsp; Προσθήκη
        </button>
        <button on:click={cancel} class="button is-light pull-right">
          Άκυρο
        </button>
      </footer>
    </div>
  </div>
{/if}

<!-- <div>{JSON.stringify(addReferences)}</div> -->
<style>
  :global(.bordered-bottom) {
    border-bottom: 1px solid lightgrey;
    padding: 1rem !important;
  }

  .line-through {
    text-decoration: line-through;
  }
</style>
