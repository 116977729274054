
import moment from "moment";
import "moment/locale/el";

export function byName(a, b) {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else return 0;
  }

 export function byLastName(a, b) {
    if (a.personDto.lastName > b.personDto.lastName) {
      return 1;
    } else if (a.personDto.lastName < b.personDto.lastName) {
      return -1;
    } else return 0;
  }

export const findUnitName = (id, units) => {
  // alert("the id is " + id + " " + JSON.stringify(units));
  let selectedUnit = units.filter(unit => unit.uuid === id);

  if (selectedUnit.length) {
    return selectedUnit[0].name;
  } else {
    return null;
  }
}

export const convertDateSmart = date => {
  moment.locale('el');
  if (!date) {
    return null;
  }
  let now = moment();
  let this_date = moment(date);
  if (now.diff(this_date, "months") > 2) {
    return moment(date).format("LL, LT");
    // return moment(date).fromNow();
    // return moment(date).format("D MMM YYYY");
    // return moment(date).format('D MMM, LT');
    // return moment(date).format('LL, LT');
  } else {
    return moment(date).format("D MMM, LT");
  }
};