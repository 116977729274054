export const removeAccents = value =>

  value
    .toLowerCase()
    .replace(/ά/g, "α")
    .replace(/έ/g, "ε")
    .replace(/ύ/g, "υ")
    .replace(/ϋ/g, "υ")
    .replace(/ί/g, "ι")
    .replace(/ϊ/g, "ι")
    .replace(/ΐ/g, "ι")
    .replace(/ή/g, "η")
    .replace(/ό/g, "ο")
    .replace(/ώ/g, "ω")
    .replace(/ς/g, "σ");

export const labelFunction = item => {
  if (item.personDto) {
    item = item.personDto;
  }
  let keyword = "";
  if (item.lastName) {
    keyword += item.lastName + " ";
  }
  keyword += item.name;
  if (item.workEmail) {
    keyword += " " + item.workEmail;
  }
  if (item.authUserName) {
    keyword += " " + item.authUserName;
  }
  return keyword;
};

// export const keywordsFunction = item => {
//     let label = labelFunction(item);
//     return label + " " + removeAccents(label);
//   };