<script>
  import { params, url, goto } from "@sveltech/routify";
  import { axiosAuth, commons } from "../../auth.js";
  import LogEvent from "../../components/_LogEvent.svelte";
  import { convertDateSmart } from "../../utils.js";
  import Spinner from "svelte-spinner";
  import { EyeIcon, ListIcon } from "svelte-feather-icons";

  let showJSON = false;

  // let logDetail = logDetailData.data;

  let logDetail;

  // if(logDetailData.data.id != $params.log) {
  //   logDetail = null;
  // }

  /////
  async function getLog() {
    if ($commons && $commons.orgId) {
      try {
        const res = await $axiosAuth.get(
          "/aade/org/" + $commons.orgId + "/logs/" + $params.log,
        );
        logDetail = await res.data.data;

        setTimeout(() => {
          try {
            window.scrollTo({
              top: 0,
              inline: "center",
              block: "center",
            });
            console.log("scrolled 1");
          } catch {
            //for older browsers
            document.getElementById("logtitle").scrollIntoView(false);
            console.log("scrolled 2");
          }
        }, 10);
      } catch (error) {
        throw new Error(error);
        console.log(error);
        console.log(res);
      }
    }
  }

  $: {
    if ($params && $params.log) {
      let promise1 = getLog();
    }
  }
</script>

<div id="logtitle"></div>

{#if logDetail}
  <div
    class="white-fade-top is-flex justify-content-center"
    style="position:sticky; top:60px;"
  >
    <div class="title">
      {convertDateSmart(logDetail.dateOfSync)} &nbsp;
      {#if logDetail.previewMode}
        <div class="tag is-small"><EyeIcon /> &nbsp; Προεπισκόπηση</div>
        &nbsp;
      {/if}
    </div>

    <div class="tabs is-toggle">
      <ul>
        <li class:is-active={!showJSON} class="has-background-white">
          <a on:click={() => (showJSON = !showJSON)}>
            <ListIcon size="24" />
          </a>
        </li>

        <li class:is-active={showJSON} class="has-background-white">
          <a on:click={() => (showJSON = !showJSON)}>
            <div style="width:24px; height:24px;">
              <div style="font-size:20px; margin-top:-3px">
                {"{ }"}
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <div style="height:0.5rem" />
  </div>

  {#if logDetail.entries}
    {#each logDetail.entries as event}
      <LogEvent {event} {showJSON} />
    {/each}
  {/if}
{/if}
