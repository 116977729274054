<script>
	import { url } from "@sveltech/routify";
	import { axiosAuth, commons, profile } from "../../auth.js";
	import { convertDateSmart } from "../../utils.js";
	import { logList, lastSeenLog } from "./logsStore.js";

	import { fade, scale, fly } from "svelte/transition";
	import { toast } from "@zerodevx/svelte-toast";
	import Spinner from "svelte-spinner";
	import Switch from "svelte-switch";
	import Assign from "../../components/Assign.svelte";
	import {
		EyeIcon,
		BookOpenIcon,
		AlertTriangleIcon,
		ChevronRightIcon,
		ChevronDownIcon,
		SaveIcon,
		ListIcon,
		PlusIcon,
		Trash2Icon,
		XIcon,
	} from "svelte-feather-icons";

	const logs = window.env ? window.env.LOGS : __process.env.LOGS;

	let loading = false;

	const getLogList = async function () {
		if ($lastSeenLog) {
			setTimeout(() => {
				try {
					document
						.getElementById($lastSeenLog)
						.scrollIntoView({ block: "top", inline: "top" });
				} catch {
					//for older browsers
				}
			}, 10);
		}

		if ($commons && $commons.orgId) {
			loading = true;
			try {
				const res = await $axiosAuth.get(
					"/aade/org/" + $commons.orgId + "/logs",
				);

				$logList = await res.data.data;

				// alert(JSON.stringify(logList));

				loading = false;
			} catch (error) {
				throw new Error(error);
				console.log(error);
				console.log(res);
				loading = false;
			}
		}
	};

	$: if ($commons && $commons.orgUuid) {
		let promise1 = getLogList();
	}
</script>

{#if logs}
	<div
		class="white-fade-top is-flex justify-content-center"
		style="position:sticky; top:60px;"
	>
		<div class="level">
			<div class="title">Καταγραφές συγχρονισμού με HRMS</div>
			<a title="Δεδομένα HRMS" href={$url("../hrms-data")}>
				<button class="button level"
					><BookOpenIcon size="24" />&nbsp;&nbsp;Δεδομένα HRMS</button
				>
			</a>
		</div>
	</div>

	{#if $logList && $logList.length}
		<ul class="menu-list">
			{#each $logList as event}
				{#if event.id == $lastSeenLog}
					<div
						style="position:absolute; margin-top:16px; margin-left:-8px"
					>
						<ChevronRightIcon size="24" />
					</div>
				{/if}
				<li in:fade id={event.id}>
					<a
						class:has-background-light={event.id == $lastSeenLog}
						class="bordered-bottom"
						on:click={() => ($lastSeenLog = event.id)}
						href={$url("/logs/:log", {
							log: event.id,
						})}
					>
						<div class="level-item level-left">
							{convertDateSmart(event.dateOfSync)}
							{#if event.previewMode}
								&nbsp; <div class="tag is-small">
									<EyeIcon size="24" /> &nbsp; Προεπισκόπηση
								</div>
							{/if}
						</div>
					</a>
					<!-- {JSON.stringify(event)} -->
				</li>
			{/each}
		</ul>
	{/if}

	{#if loading}
		...
	{:else}{/if}
{:else}
	<div class="text-center">
		<p class="is-size-3">Δεν βρέθηκε κάτι.</p>

		<p style="margin-top:2rem">
			<a href={$url("/structures")}>
				<button class="button is-link is-medium">
					Επιστροφή στην αρχή
				</button>
			</a>
		</p>
	</div>
{/if}
