<script>
    import { url } from "@sveltech/routify";
    import { axiosAuth, commons, profile } from "../../../auth.js";
    import { hrmsData } from "../logsStore.js";
    import Spinner from "svelte-spinner";

    import { ArrowLeftIcon } from "svelte-feather-icons";

    const logs = window.env ? window.env.LOGS : __process.env.LOGS;
    let loading = false;
    let error = "";

    const getHRMSData = async function () {
        error = "";
        if ($commons && $commons.orgId) {
            loading = true;
            try {
                const res = await $axiosAuth.get(
                    "/aade/org/" + $commons.orgId + "/hrmsentries",
                );

                $hrmsData = res.data.data;

                loading = false;
            } catch (e) {
                loading = false;
                $hrmsData = [];
                error = e.message;
                throw new Error(e);
            }
        }
    };

    $: if ($commons && $commons.orgUuid) {
        let promise2 = getHRMSData();
    }
</script>

{#if logs}
    <!-- <div class="sticky-top">
        <p style="margin-left:-2rem; margin-bottom:2rem">
            <a href={$url("/logs")}>
                <button class="button">
                    <ArrowLeftIcon size="24" />
                </button>
            </a>
        </p>
    </div> -->
    <div
        class="white-fade-top is-flex justify-content-center"
        style="position:sticky; top:60px;"
    >
        <div class="title" style="margin-bottom:2rem">Δεδομένα HRMS</div>
    </div>

    {#if error}
        {error}
    {/if}
    {#if loading}
        <Spinner />
    {/if}

    {#if $hrmsData && $hrmsData.length}
        ({$hrmsData.length})
        {#each $hrmsData as h, i}
            <div class="columns" style="margin-top:1rem">
                <div class="column is-narrow is-bold" style="margin-top:1rem">
                    {i + 1}
                </div>
                <div class="column">
                    <pre>
                    {JSON.stringify(h, null, 2)}
                </pre>
                </div>
            </div>
        {/each}
    {/if}
{:else}
    <div class="text-center">
        <p class="is-size-3">Δεν βρέθηκε κάτι.</p>

        <p style="margin-top:2rem">
            <a href={$url("/structures")}>
                <button class="button is-link is-medium">
                    Επιστροφή στην αρχή
                </button>
            </a>
        </p>
    </div>
{/if}
