<script>
  import { axiosAuth, commons } from "../auth.js";
  import { params, url } from "@sveltech/routify";
  import Spinner from "svelte-spinner";
  import { fade } from "svelte/transition";

  export let chart;
  export let department;

  let jobGroups = [];

  function sortByPersonName(a, b) {
    var nameA = a.personFullName.toUpperCase(); // ignore upper and lowercase
    var nameB = b.personFullName.toUpperCase(); // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  function sortByJobName(a, b) {
    var nameA = a.jobDescriptionTitle.toUpperCase(); // ignore upper and lowercase
    var nameB = b.jobDescriptionTitle.toUpperCase(); // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  let waitingForAssignments = true;

  async function getAssignments(clientUuid) {
    try {
      //  /v2/{clientUuid}/assignments/{orgChartUuid}
      // /v2/{clientUuid}/assignments/{orgChartUuid}/unit/{unitUuid}
      const res = await $axiosAuth.get(
        "/v2/" + clientUuid + "/assignments/" + chart + "/unit/" + department
      );
      jobGroups = await res.data
        .sort(sortByJobName)
        .reduce((groups, assignment) => {
          if (
            groups.length &&
            groups[groups.length - 1].jobDescriptionUuid ===
              assignment.jobDescriptionUuid
          ) {
            groups[groups.length - 1].people.push(assignment);
          } else {
            groups.push({
              jobDescriptionTitle: assignment.jobDescriptionTitle,
              jobDescriptionUuid: assignment.jobDescriptionUuid,
              people: [assignment]
            });
          }
          return groups;
        }, [])
        .map(group => {
          group.people = group.people.sort(sortByPersonName);
          return group;
        });
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    } finally {
      waitingForAssignments = false;
    }
  }

  let clientUuid = 0;

  $: {
    clientUuid = $commons.orgUuid;

    if (clientUuid && chart && department) {
      let promise = getAssignments(clientUuid);
    }
  }
</script>

<!-- class="has-text-weight-bold" -->
<!-- <ul class="menu-list"> -->
{#each jobGroups as jobGroup, i}
  <div style="margin-top:2rem" in:fade|local>
    <!-- <li> -->
    <h3 class="is-size-6 label">
      <span class="has-text-weight-semibold">
        {jobGroup.jobDescriptionTitle}
      </span>
      &nbsp; {jobGroup.people.length}
    </h3>
    {#if jobGroup.people.length}
      <div class="tags are-medium">
        {#each jobGroup.people as person, i}
          {#if i > 0}, {/if}
          {person.personFullName}
        {/each}
      </div>
      {#if false}
        <ul class="menu-list">
          {#each jobGroup.people as person}
            <li>
              <a
                href={$url('/person/:person', {
                  chart: $params.chart,
                  person: person.personUuid
                })}>
                {person.personFullName}
              </a>
            </li>
          {/each}
        </ul>
      {/if}
    {/if}
    <!-- {JSON.stringify(assignment)} -->
    <!-- </li> -->
  </div>
{/each}
{#if jobGroups.length > 0}
<div style="margin-top:.5rem">&nbsp;</div>
{/if}
{#if waitingForAssignments}
  <div in:fade|local class="columns is-centered">
    <div style="margin-top:5rem">
      <Spinner size="40" speed="750" thickness="2" gap="40" />
    </div>
  </div>
{/if}
<!-- </ul> -->
