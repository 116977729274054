<script>
  import { Router } from "@sveltech/routify";
  import { routes } from "@sveltech/routify/tmp/routes";
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import SSO from "./sso.svelte";
  import SessionCheck from "./sessioncheck.svelte";

  const options = {
    duration: 3000, // duration of progress bar tween
    dismissable: true, // allow dismiss with close button
    initial: 0, // initial progress bar value
    progress: 1, // current progress
    reversed: true, // insert new toast to bottom of stack
    intro: { y: 100 }, // toast intro fly animation settings
  };

  function warn(e) {
    alert(JSON.stringify(e.detail.text));
  }
</script>

<style>
  :global(body) {
    --toastContainerTop: auto;
    --toastContainerRight: auto;
    --toastContainerBottom: 1rem;
    --toastContainerLeft: calc(50vw - 8rem);
    --toastProgressBackground: transparent;
  }
</style>

<SSO>
  <Router {routes} />
  <SvelteToast {options} />
  <SessionCheck />
</SSO>
