<script>
  import AutoComplete from "simple-svelte-autocomplete";
  import { axiosAuth, commons } from "../auth.js";
  import { units, roles, people, groups, charts } from "../corpjobperson.js";
  import { removeAccents, labelFunction } from "../removeaccents.js";

  export let type;
  export let selectedItem;
  export let selectedChart; //useful when the selectedItem is a unit and there is a given selectedChart
  export let initUuid = null;

  let items = [];

  async function getItems(clientUuid, orgChartUuid) {
    try {
      const res = await $axiosAuth.get(
        "/" + clientUuid + "/structure/" + orgChartUuid + "/corpjobperson"
      );
      $units = await res.data.responseCorporateNanoDto;
      $roles = await res.data.responseJobDescriptionNanoDto;
      // $people = await res.data.responsePersonNanoDto; // doesn't return people without assignments
      // $groups = await res.data.synonyms; //doesn't return groups without assignments

      // console.log("yes", res);
    } catch (error) {
      throw new Error(error);
      console.log(units);
      console.log(res);
    }
  }

  function clear() {
    selectedItem = null;
  }

  let prevSelectedChart = null;
  //ensures that the following reactive statement doesn't get triggered if there was a chart selection

  $: if (selectedChart != prevSelectedChart && type == "unit") {
    prevSelectedChart = selectedChart;
    clear();
    getItems($commons.orgUuid, selectedChart.uuid);
  }

  $: if (type) {
    switch (type) {
      case "person":
        items = $people;
        // alert(JSON.stringify($people));
        break;

      case "role":
        items = $roles;
        break;

      case "unit":
        items = $units;
        break;

      case "chart":
        items = $charts;
        break;

      default:
        // items = $charts;
        break;
    }

    if (items && items.length && items.length == 1) {
      selectedItem = items[0];
    }
  }

  $: if (!selectedItem && initUuid) {
    let result = items.find(function (x) {
      return x.uuid == initUuid;
    });

    if (result) {
      selectedItem = result;
    }
  }


  function changed() {}
</script>

<AutoComplete
  {items}
  bind:selectedItem
  onChange={changed}
  keywordsFunction={labelFunction}
  {labelFunction}
  keywordsCleanFunction={removeAccents}
  textCleanFunction={removeAccents}
  placeholder="Αναζήτηση..."
/>

<!-- {JSON.stringify(selectedItem)} -->
