<script>
  import { params, url, goto } from "@sveltech/routify";
  import { axiosAuth, commons } from "../../../../../../auth.js";
  import Unit from "../../../_Unit.svelte";
  import Assign from "../../../../../../components/Assign.svelte";
  import Department from "../../../../../../components/_Department.svelte";
  import PrintAssignments from "../../../../../../components/_PrintAssignments.svelte";
  import ChartsMenu from "../../../../../../components/_ChartsMenu.svelte";
  import { fade, scale, fly } from "svelte/transition";
  import { PlusIcon, XIcon, Trash2Icon } from "svelte-feather-icons";
  import Spinner from "svelte-spinner";
  import moment from "moment";
  // $: console.log($params);

  let chart;
  let units = [];

  async function getChart(clientUuid) {
    try {
      // /{clientUuid}/structure/{orgChartUuid}
      const res = await $axiosAuth.get(
        "/" + clientUuid + "/structure/" + $params.chart
      );
      chart = await res.data;
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    }
  }

  async function getUnits(clientUuid) {
    try {
      const res = await $axiosAuth.get(
        "/" + clientUuid + "/structure/" + $params.chart + "/corpjobperson"
      );
      units = await res.data.responseCorporateNanoDto;
      units = units.sort((a,b) => { if(a.name > b.name) return 1; else if(a.name < b.name) return -1; else return 0;});
      // console.log("yes", res);
    } catch (error) {
      throw new Error(error);
      console.log(units);
      console.log(res);
    }
  }

  let clientUuid = 0;

  $: if ($params.chart) {
    clientUuid = $commons.orgUuid;

    if (clientUuid) {
      let promise = getChart(clientUuid);
      let promise2 = getUnits(clientUuid);
      // alert(
      //   "Για να εκτυπώσετε πατήστε Ctrl + P.\nΕπίσης, μπορείτε να επιλέξετε όλο το κείμενο με Ctrl + A, να το αντιγράψετε με Ctrl + C και να το επικολλήσετε σε έναν επεξεργαστή κειμένου."
      // );
    }
  }
</script>

{#if chart}
  <h1 class="is-size-3">{chart.name}</h1>
{/if}

{#each units as unit, i}
  <hr />
  <h2 class="is-size-4">{unit.name}</h2>
  <!-- <p>{JSON.stringify(unit)}</p> -->
  <PrintAssignments chart={$params.chart} department={unit.uuid} />
{:else}
  <div in:fade|local class="columns" style="padding:2rem">
    <div style="margin-top:5rem">
      <Spinner size="40" speed="750" thickness="2" gap="40" />
    </div>
  </div>
{/each}
