<script>
    import { getCookie, logoutOLD } from "./authOLD.js";
    import moment from "moment";
    import { fade, scale, fly } from "svelte/transition";
    import { onMount } from "svelte";


    const KEYCLOAK_URL = window.env ? window.env.KEYCLOAK_URL : __process.env.KEYCLOAK_URL;

    let open = false;
    let hours = 0;
    let minutes = 0;

    function cancel() {
        open = false;
    }

    let logging_out = false;

    function logoutNow() {
        open = false;
        logging_out = true;
        logoutOLD();
    }

    let fifteenMinutesWarning = false;
    let fiveMinutesWarning = false;

    //periodically checks if SSO cookie exists
    const checkAuthCookie = () => {
        console.log("checking cookie...");

        if(KEYCLOAK_URL) return;
    
        let ssoAuth = getCookie("ssoAuth");
        if (!ssoAuth || !ssoAuth.expires) {
            console.log("cookie not found");
            logoutNow();
        } else {
            // console.log("cookie is OK", ssoAuth);
            // check if it expires soon or is expired already: ssoAuth.expires
            if (ssoAuth.expires) {
                let now = moment();
                minutes = moment(ssoAuth.expires).diff(now, "minutes");
                console.log("minutes remaining:", minutes);
                if (!minutes) {
                    logoutNow();
                }
                if (minutes == 15 && !fifteenMinutesWarning) {
                    fifteenMinutesWarning = true;
                    hours = Math.round(ssoAuth.duration / 3600);
                    open = true;
                }
                if (minutes == 5 && !fiveMinutesWarning) {
                    fiveMinutesWarning = true;
                    hours = Math.round(ssoAuth.duration / 3600);
                    open = true;
                }
            }
        }

        window.setTimeout(checkAuthCookie, 5000); // check every 5 seconds
    };

    onMount(checkAuthCookie);
</script>

{#if logging_out}
    <div class="modal" class:is-active={logging_out}>
        <div in:fade|local class="modal-background" />
        <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card" >
            <section class="modal-card-body has-text-centered is-size-3">
                Γίνεται αποσύνδεση...
            </section>
        </div>
    </div>
{/if}

{#if open}
    <div class="modal" class:is-active={open}>
        <div in:fade|local on:click={cancel} class="modal-background" />
        <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card" >
            <header class="modal-card-head">
                <p class="modal-card-title">Προσοχή</p>
                <button class="delete" on:click={cancel} aria-label="close" />
            </header>
            <section class="modal-card-body">
                {#if hours}
                    <p>
                        Έχουν περάσει σχεδόν
                        {hours}
                        ώρες από τη στιγμή που συνδεθήκατε.
                    </p>
                {/if}
                <p>
                    Θα γίνει αυτόματη αποσύνδεση σε
                    {minutes}
                    {#if minutes == 1}λεπτό{:else}λεπτά{/if}.
                </p>
                <!-- <p>
                    Για να συνεχίσετε περισσότερη ώρα παρακαλώ αποσυνδεθείτε και
                    συνδεθείτε ξανά.
                </p> -->
            </section>
            <footer class="modal-card-foot">
                <button
                    on:click={logoutNow}
                    class="button is-danger is-light pull-right"
                >
                    Αποσύνδεση τώρα
                </button>
                <button on:click={cancel} class="button is-light pull-left">
                    Συνέχιση για
                    {minutes}
                    {#if minutes == 1}λεπτό{:else}λεπτά{/if}
                </button>
            </footer>
        </div>
    </div>
{/if}
