<script>
  import { url } from "@sveltech/routify";
</script>

<div class="text-center">

  <p class="is-size-3">Δεν βρέθηκε κάτι.</p>

  <p style="margin-top:2rem">
    <a href={$url('../structures')}>
      <button class="button is-link is-medium">
        Επιστροφή στην αρχή
      </button>
    </a>
  </p>

</div>