<script>
  let show = false;
  let stavrosurl = "http://10.0.3.73:8080/";
  let stagingurl = "https://tukanga-staging.open1.eu/";
  let baseurl = stavrosurl;
  let staging = false;
  let extension = "tukanga/api/websocket";
  let connected = false;
  $: socketurl = baseurl + extension;
  $: if (staging) {
    baseurl = stagingurl;
  } else {
    baseurl = stavrosurl;
  }

  function connect() {
    let socket = new SockJS(socketurl);
    let stompClient = Stomp.over(socket);
    stompClient.connect({}, function(frame) {
      connected = true;
      console.log("Connected: " + frame);
      stompClient.subscribe("/hello/ots/", function(notification) {
        alert(notification);
      });
    });
  }
</script>

<div class="field">
  <div class="control">
    <label class="checkbox">
      <input type="checkbox" bind:checked={staging} />
      try tukanga-staging
    </label>
  </div>
</div>

<div class="field">
  <label class="label">base url</label>
  <div class="control">
    <input class="input" type="text" bind:value={baseurl} />
  </div>
</div>

<div class="field">
  <label class="label">extension</label>
  <div class="control">
    <input class="input" type="text" bind:value={extension} />
  </div>
</div>

<div class="field">
  <label class="label">socket url</label>
  <div class="control">
    <input class="input is-success" type="text" bind:value={socketurl} />
  </div>
</div>

<div class="field">
  <button on:click={connect} class="button is-success">
    <b>Test</b>
  </button>
</div>

{#if show}
  <div>show me!</div>
{/if}
