<script>
  import { axiosAuth, commons } from "../auth.js";
  import { url } from "@sveltech/routify";
  import { charts, gettingCharts } from "../corpjobperson.js";
  import { CheckIcon, PlusIcon } from "svelte-feather-icons";
  import { fade, scale, fly } from "svelte/transition";
  import Spinner from "svelte-spinner";

  // $: console.log(charts);
  let open = false;
  let newChartName;

  async function deleteChart(chartUuid) {
    let url =
      "/" +
      $commons.orgUuid +
      "/structure/" +
      chartUuid +
      "/organization?divisionUuid=''&recursiveDelete=true";
    try {
      let deletedResponse = await $axiosAuth.delete(url);
      return deletedResponse;
      console.log("delete result", res);
    } catch (error) {
      alert(JSON.stringify(error.message));
    }
  }

  const cancel = () => (open = false);

  async function addChart() {
    let orgChartDto = {
      orgChartName: newChartName,
      validFrom: "",
      validUntil: "",
      organizationDto: {
        name: "Νέα κορυφαία οργανωτική μονάδα",
        postalCode: "",
        description: "",
        phone: "",
        fax: "",
        email: "",
        orderNo: 0,
        abbreviation: "",
        city: "",
        address: "",
      },
    };

    let result;

    try {
      const res = await $axiosAuth.post(
        "/" + $commons.orgUuid + "/structure",
        orgChartDto
      );

      result = await res.data;
    } catch (error) {
      alert(error);
    } finally {
      open = false;
      if (result) {
        // alert(JSON.stringify(result));
        $charts = [
          ...$charts,
          { name: result.orgChartName, uuid: result.orgChartUuid },
        ];
      }
    }
  }

  // async function getCharts(clientUuid) {
  //   try {
  //     const res = await $axiosAuth.get("/client/" + clientUuid + "/charts");
  //     $charts = await res.data.structureCharts;
  //   } catch (error) {
  //     throw new Error(error);
  //     console.log(error);
  //     alert(error);
  //   }
  // }

  // let clientUuid = 0;
  // let promise;

  // $: {
  //   clientUuid = $commons.orgUuid;

  //   if (clientUuid) {
  //     promise = getCharts(clientUuid);
  //   }
  // }
</script>

<!-- <h1 class="title">
      Charts
      Οργανογράμματα
      {#if !charts.length}
        {/if}
    </h1> -->

<div class="title">Οργανογράμματα</div>

{#if $gettingCharts}
  <Spinner size="40" speed="750" thickness="2" gap="40" />
{:else}
  <ul class="menu-list">
    {#each $charts as chart}
      <li in:fade>
        <a
          class="bordered-bottom"
          href={$url("structure/:chart/department/:department", {
            chart: chart.uuid,
            department: "all",
          })}
        >
          {chart.name}
          <!-- {chart.uuid} -->
          <!-- {JSON.stringify(chart)} -->
        </a>
      </li>
      <!-- <button on:click={deleteChart(chart.uuid)}> x </button> -->
    {:else}
      {#if !$charts}
        <div in:fade|local class="columns" style="padding:2rem">
          <div style="margin-top:5rem">
            <Spinner size="40" speed="750" thickness="2" gap="40" />
          </div>
        </div>
      {/if}
    {/each}
  </ul>
{/if}

<div class="sticky-bottom white-fade">
  <button class="button is-link sticky-bottom" on:click={() => (open = true)}>
    <PlusIcon size="1.5x" />
    &nbsp; Νέο οργανόγραμμα
  </button>
</div>

<!-- add structure modal -->
{#if open}
  <div class="modal" class:is-active={open}>
    <div out:fade|local on:click={cancel} class="modal-background" />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Νέο οργανόγραμμα</p>
        <button class="delete" on:click={cancel} aria-label="close" />
      </header>
      <section class="modal-card-body">
        <input
          type="text"
          bind:value={newChartName}
          placeholder="όνομα οργανογράμματος"
          class="input"
          autofocus
        />
      </section>
      <footer class="modal-card-foot">
        <button
          disabled={!newChartName}
          on:click={addChart}
          class="button is-pulled-left is-link"
        >
          <!-- <CheckIcon size="1.5x" /> -->
          &nbsp; Προσθήκη
        </button>
        <button on:click={cancel} class="button is-light pull-right">
          Άκυρο
        </button>
      </footer>
    </div>
  </div>
{/if}
