<script>
  export let name = "entrance";
</script>

<div style="display:flex; align-items:center">
  <svg width="1em" height="1em" viewBox="0 0 16 20">
    <circle
      cx="10"
      cy="11.5"
      r="5.6"
      fill="none"
      stroke="currentColor"
      stroke-width="1.8"
      stroke-dasharray="24 4"
    />
  </svg>
  <span style="margin-left:-0.7em; color:transparent">O</span>pen
  <span style="width:0;color:transparent">1</span>
  <svg
    style="margin-left:-0.3em; margin-right:-0.3em;"
    width="1em"
    height="1.5em"
    viewBox="0 0 24 32"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="square"
    stroke-linejoin="round"
    ><line x1="12" y1="29" x2="12" y2="7" /><polyline points="10 7 11 7" /></svg
  >
  <div style="margin-top:-3px; letter-spacing: 0.06rem; font-size: 1.3em; font-weight:300">
    {name}
  </div>
</div>
