import Keycloak from "keycloak-js";
import axios from "axios";

let keycloakInstance;
let ORG_URL;
let KEYCLOAK_URL;
let KEYCLOAK_REALM;
let KEYCLOAK_CLIENT_ID;

const KEYCLOAK_OPTIONAL_ORG_ID = window.env ? window.env.KEYCLOAK_OPTIONAL_ORG_ID : __process.env.KEYCLOAK_OPTIONAL_ORG_ID;

const TAB_ID = (Date.now() + Math.floor(Math.random() * 10 + 1)).toString(36);

let orgUserInfo = null;

// defaults example:

// const defaults = {
//     ORG_URL: "https://org-staging.open1.eu/back-keycloak/",
//     keycloak_url: "https://keycloak-staging.open1.eu" ,
//     keycloak_realm: "dc",
//     keycloak_client_id: "org-view"
// }


// create organization if it doesn't exist
const createOrgChart = async (thirdPartyId, name) => {

    let myaxios = await getAxiosInstance();

    try {
        let res = await myaxios
            .post(ORG_URL + "client", { id: thirdPartyId, name: name });
        // alert("1 here" + JSON.stringify(res));
    } catch (error) {
        // alert("3" + JSON.stringify(error));
    }

}


export const createServiceInstance = (defaults) => {

    if (!keycloakInstance) {

        ORG_URL = defaults.org_url;
        KEYCLOAK_URL = defaults.keycloak_url;
        KEYCLOAK_REALM = defaults.keycloak_realm;
        KEYCLOAK_CLIENT_ID = defaults.keycloak_client_id;

        keycloakInstance = new Keycloak({ url: KEYCLOAK_URL, realm: KEYCLOAK_REALM, clientId: KEYCLOAK_CLIENT_ID });

        keycloakInstance.onTokenExpired = () => { keycloakInstance.updateToken(5).then((refreshed) => { console.log('Token refreshed'); }).catch((error) => { console.error('Error refreshing token:', error); }) };

    }
}


// if another tab logs out: every tab will logout
// if another tab logs in: every tab will login
window.addEventListener("storage", (event) => {
    if (event.key === 'login') {
        let login = localStorage.getItem("login");
        if (!login) {
            keycloakLogout();
        } else if (keycloakInstance && !keycloakInstance.authenticated) {
            console.log("storage 3", login);
            keycloakLogin('check-sso');
        }
    }
});

export const keycloakLogin = async (onLoad = 'login-required') => {

    try {
        // login to keycloak
        const authenticated = await keycloakInstance.init(
            {
                flow: 'standard',
                pkceMethod: 'S256',
                onLoad: onLoad,
                checkLoginIframe: false
            }
        );

        console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);

        // inform other open tabs that login was successful

        if (authenticated) {

            let login = localStorage.getItem('login');

            if (login == null) {
                localStorage.setItem('login', TAB_ID);
            }
        }

        return authenticated;


    } catch (error) {
        console.error('Failed:', error);
        keycloakLogout();
    }
}

export const keycloakLogout = async () => {
    // log out from keycloak
    orgUserInfo = null;

    //signal logout to other tabs
    localStorage.removeItem('login');

    if (keycloakInstance) {
        await keycloakInstance.logout(location.origin);
    }
}

export const getLoginDetails = async () => {

    try {

        // get user info from KEYCLOAK
        let keycloakUserInfo = await keycloakInstance.loadUserInfo();

        // TODO: handle multiple organizations connected to the same user



        let thirdPartyId = KEYCLOAK_OPTIONAL_ORG_ID || keycloakUserInfo.orgId;

        // let thirdPartyId = prompt("Επειδή δεν υπάρχει αντιστοίχιση μεταξύ οργανισμών στο Keycloak και οργανισμών στο open1SSO, παρακαλώ καταχωρίστε το id του οργανισμού π.χ. 7 από το open1SSO. Αν επιλέξετε Άκυρο θα επιλεγεί ο 7.");
        // if (thirdPartyId == null) {
        //     thirdPartyId = 7; 
        // }


        if (!thirdPartyId) {

            if (confirm("Δεν έχει προσδιοριστεί ο οργανισμός. Δοκιμάστε να συνδεθείτε ξανά.")) {
                keycloakLogout();
            }
            return;
        }

        let orgUserInfo = { id: thirdPartyId };

        // Get uuid from keycloak id
        let res = await axios
            .get(ORG_URL + "client/thirdParty/" + thirdPartyId, {
                headers: {
                    Authorization: "Bearer " + keycloakInstance.token
                }
            });

        let uuid;

        let orgName = "New";

        try {
            uuid = res.data.uuid;
            orgName = res.data.name;
        } catch (error) {

            console.error(error, res);

        }

        if (!uuid) {

            if (confirm("Δεν βρέθηκε οργανόγραμμα για τον οργανισμό " + thirdPartyId + "\n. Πατήστε ΟΚ για να δημιουργηθεί νέο.")) {

                await createOrgChart(thirdPartyId, orgName);

                // keycloakLogin('check-sso');

            } else {
                // keycloakLogout();
            }

        }

        // let uuid = "99824d9c-a253-4118-9208-d42787fb8741";

        //TODO get user info from ORG

        try {

            let res2 = await axios
                .get(ORG_URL + uuid + "/person/me", {
                    headers: {
                        Authorization: "Bearer " + keycloakInstance.token
                    }
                });

            orgUserInfo = res2.data;

            console.log("org user info is ", orgUserInfo);
        } catch (error) {


        }

        orgUserInfo.uuid = uuid;

        return { keycloakUserInfo: keycloakUserInfo, orgUserInfo: orgUserInfo }

    } catch (error) {
        console.log("error in getting login details: ", error);
    }


}



const base64EncodeUnicode = (str) => {
    // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
    // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
    let utf8Bytes = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode('0x' + p1);
    });

    return btoa(utf8Bytes);
};


let axiosInstance;

let refreshTokenPromise; // queue up requests

// const startPolling = () => {
//     setTimeout(() => {
//         console.log("refreshing the token after 30 seconds");
//         if (refreshTokenPromise == null) {
//             refreshTokenPromise = keycloakInstance.updateToken(60);
//         }
//         startPolling();
//     }, 30 * 1000);
// }

export const getAxiosInstance = async () => {

    try {

        if (!axiosInstance) {

            // setup axios instance
            axiosInstance = axios.create({
                baseURL: ORG_URL
            });

            // Add a response interceptor
            axiosInstance.interceptors.response.use(
                response => {
                    // If the response is successful, return it directly
                    return response;
                },
                error => {
                    // If the response status is 401 (Unauthorized), handle it here
                    if (error.response.status === 401) {
                        // Handle the 401 error, for example, redirect to login page
                        console.log('Unauthorized access. Redirecting to login page.');
                        // You can also throw an error here to stop further execution
                        keycloakLogin('check-sso');
                        return Promise.reject(error);
                    }
                    // For other errors, return the error as is
                    return Promise.reject(error);
                }
            );

            // Add a request interceptor
            axiosInstance.interceptors.request.use(async (config) => {
                try {
                    if (refreshTokenPromise == null) {
                        refreshTokenPromise = keycloakInstance.updateToken(30);
                    }
                    const res = await refreshTokenPromise;
                    refreshTokenPromise = null;

                    config.headers.Authorization = `Bearer ${keycloakInstance.token}`;

                    if (orgUserInfo && orgUserInfo.data) {
                        config.headers.Open1OrgApiData = base64EncodeUnicode(JSON.stringify(orgUserInfo.data));
                    }

                    return config;

                } catch (error) {
                    console.log("error in interceptor", error);
                    keycloakLogin('check-sso');
                    // return config;
                }
            });


            // stay logged in, even if the user is inactive
            // if (true) {
            //     startPolling();
            // }

        }

        return axiosInstance;

    } catch (error) {
        console.log("error refreshing token " + TAB_ID + "  : ", error);
        // keycloakInstance.clearToken();
        // keycloakLogin();
    }
}
