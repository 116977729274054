<script>
  import { isActive, url, params } from "@sveltech/routify";

  import { commons } from '../auth.js';

  export let hideLabelsOnDesktop = true;

  let logs = window.env ? window.env.LOGS : __process.env.LOGS;

  let ldap = false;

  $: if ($commons && $commons.ldap) {
    ldap = true;
  } else {
    ldap = false;
  }

  import {
    UserIcon,
    UsersIcon,
    DatabaseIcon,
    KeyIcon,
    BookmarkIcon,
    HomeIcon,
    ClockIcon,
    SettingsIcon,
  } from "svelte-feather-icons";
</script>

<a title="Οργανογράμματα" class="navbar-item" class:is-active={$isActive('../structures')} href={$url('../structures')}>
  <HomeIcon size="1.5x" />
  <span class:is-hidden-desktop={hideLabelsOnDesktop}>&nbsp; Οργανογράμματα</span>
</a>

<a title="Ρόλοι" class="navbar-item" class:is-active={$isActive('../role/:role')} href={$url('../role/:role', {
  role: 'all' })}>
  <KeyIcon size="1.5x" />
  <span class:is-hidden-desktop={hideLabelsOnDesktop}>
    <!-- Roles -->
    &nbsp; Ρόλοι
  </span>
</a>

<a title="Πρόσωπα" class="navbar-item" class:is-active={$isActive('../person/:person')} href={$url('../person/:person',
  { person: 'all' })}>
  <UserIcon size="1.5x" />
  <span class:is-hidden-desktop={hideLabelsOnDesktop}>
    <!-- People -->
    &nbsp; Πρόσωπα
  </span>
</a>


<a title="Ομάδες" class="navbar-item" class:is-active={$isActive('../group/:group')} href={$url('../group/:group', {
  group: 'all' })}>
  <!-- <BookmarkIcon size="1.5x" /> -->
  <UsersIcon size="1.5x" />
  <span class:is-hidden-desktop={hideLabelsOnDesktop}>&nbsp; Ομάδες</span>
  <!-- Groups -->
</a>


{#if ldap}
<a title="Ρυθμίσεις" class="navbar-item" class:is-active={$isActive('../settings')} href={$url('../settings', { })}>
  <SettingsIcon size="1.5x" />
  <span class:is-hidden-desktop={hideLabelsOnDesktop}>&nbsp; Ρυθμίσεις</span>
  <!-- Groups -->
</a>
{/if}

{#if logs}
<a title="HRMS" class="navbar-item" class:is-active={$isActive('../logs')} href={$url('../logs', { })}>
  <!-- <ClockIcon size="1.5x" /> -->
  <DatabaseIcon size="1.5x" />
  <span class:is-hidden-desktop={hideLabelsOnDesktop}>&nbsp; HRMS</span>
  <!-- Groups -->
</a>
{/if}