<script>
  import { byLastName } from "../../utils.js";
  import { params, url, goto } from "@sveltech/routify";
  import { axiosAuth, commons } from "../../auth.js";
  import { roles } from "../../corpjobperson.js";
  import Spinner from "svelte-spinner";
  import { fade, scale, fly } from "svelte/transition";
  import { Trash2Icon, PlusIcon, SaveIcon } from "svelte-feather-icons";
  import Supervision from "../../components/_Supervisions.svelte";
  import { toast } from "@zerodevx/svelte-toast";

  let roleData;
  let clientUuid = 0;
  let saving = false;
  let openNewRole = false;
  let openDelete = false;
  let newRoleName;
  let newRoleCode;
  let supervision;

  async function addRole() {
    let requestPostRole = {
      validFrom: "",
      validUntil: "",
      jobDescriptionDto: {
        name: newRoleName,
        code: newRoleCode,
      },
    };

    let result;

    try {
      const res = await $axiosAuth.post(
        "/" + clientUuid + "/jobdescription",
        requestPostRole
      );

      result = await res.data;
    } catch (error) {
      alert(error);
    } finally {
      openNewRole = false;
      if (result) {
        $roles = [...$roles, result];
        // alert(JSON.stringify(result));
        $goto("/role/:role", { role: result.jobDescriptionDto.uuid });
      }
    }
  }

  function openNewRoleModal() {
    openNewRole = true;
    newRoleName = "";
    newRoleCode = "";
  }

  function cancelNewRole() {
    openNewRole = false;
  }

  function openDeleteModal() {
    openDelete = true;
  }

  function cancelDeleteDialog() {
    openDelete = false;
  }

  async function deleteRole() {
    try {
      const res = await $axiosAuth.delete(
        "/" + $commons.orgUuid + "/jobdescription/" + $params.role
      );
    } catch (error) {
    } finally {
      openDelete = false;
      $goto("/role/all");
    }
  }

  async function saveRole() {
    saving = true;

    supervision.save();

    let requestPutRole = {
      validFrom: "",
      validUntil: "",
      jobDescriptionDto: roleData,
    };

    let result;

    try {
      const res = await $axiosAuth.put(
        "/" + clientUuid + "/jobdescription",
        requestPutRole
      );

      result = await res.data;
      toast.push("Αποθηκεύτηκε");
    } catch (error) {
      alert(error);
    } finally {
      setTimeout(() => (saving = false), 400);
    }
  }

  async function getRole(clientUuid) {
    // /{clientUuid}/jobdescription
    try {
      const res = await $axiosAuth.get(
        "/" + clientUuid + "/jobdescription?jobDescUuid=" + $params.role
      );
      roleData = await res.data[0].jobDescriptionDto;

      setTimeout(() => {
        try {
          window.scrollTo({
            top: 0,
            inline: "center",
            block: "center",
          });
          console.log("scrolled 1");
        } catch {
          //for older browsers
          document.getElementById("roletitle").scrollIntoView(false);
          console.log("scrolled 2");
        }
      }, 10);
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    }
  }

  let people = [];
  let loadingPeople = true;

  async function getPeopleOfRole(clientUuid) {
    loadingPeople = true;
    try {
      const res = await $axiosAuth.get(
        "/" + clientUuid + "/person?jobDescriptionUuid=" + $params.role
      );
      people = await res.data;
    } catch (error) {
      throw new Error(error);
      console.log(error);
      console.log(res);
    } finally {
      loadingPeople = false;
    }
  }

  $: {
    clientUuid = $commons.orgUuid;

    if (clientUuid && $params && $params.role) {
      if ($params.role != "all") {
        let promise1 = getRole(clientUuid);
        let promise2 = getPeopleOfRole(clientUuid);
      }
    }
  }
</script>

{#if $params.role === "all"}
  <div in:fade class="title">Ρόλοι</div>
  <div class="menu">
    <ul class="menu-list">
      {#each $roles as role}
        <li in:fade>
          <a
            class="bordered-bottom"
            href={$url("/role/:role", {
              chart: $params.chart,
              role: role.uuid,
            })}
          >
            {role.name}
          </a>
          <!-- {JSON.stringify(role)} -->
        </li>
      {/each}
    </ul>

    <div class="sticky-bottom white-fade">
      <button class="button is-link sticky-bottom" on:click={openNewRoleModal}>
        <PlusIcon size="1.5x" />
        &nbsp; Νέος ρόλος
      </button>
    </div>
  </div>
{:else if roleData}
  <div id="roletitle" class="" in:fade|local>
    <div class="title">
      {roleData.name} &nbsp;
      {#if roleData.abbreviation}({roleData.abbreviation}){/if}
    </div>
    <!-- {JSON.stringify(roleData)} -->
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          <!-- Name -->
          Όνομα
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <input
              class="input"
              type="text"
              placeholder=""
              bind:value={roleData.name}
            />
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          <!-- Abbreviation -->
          Συντόμευση
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <input
              class="input"
              type="text"
              placeholder=""
              bind:value={roleData.abbreviation}
            />
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          <!-- Code -->
          Κωδικός
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <input
              class="input"
              type="text"
              placeholder=""
              bind:value={roleData.code}
            />
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">e-mail</label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <input
              class="input"
              type="text"
              placeholder=""
              bind:value={roleData.workEmail}
            />
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          <!-- Supervision -->
          Επίβλεψη
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <Supervision bind:this={supervision} jobDescUuid={$params.role} />
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          Πρόσωπα ({people.length ? people.length : ""})
        </label>
      </div>
      <div class="field-body">
        <!-- <div style="word-wrap: break-word;">{JSON.stringify(people)}</div> -->
        <div class="field">
          {#if people && people.length}
            <div class="tags are-medium">
              {#each people.sort(byLastName).sort(function (a, b) {
                if (a.personDto.disabled && !b.personDto.disabled) return 1;
                else return -1;
              }) as person}
                <a
                  class="tag  is-light {person.personDto.disabled
                    ? ''
                    : 'is-link'}"
                  href={$url("/person/:person", {
                    chart: $params.chart,
                    person: person.personDto.uuid,
                  })}
                >
                  {#if person.personDto.disabled}
                    (Aνενεργό)
                  {/if}
                  {person.personDto.personFullName ||
                    person.personDto.lastName + " " + person.personDto.name}
                </a>
              {/each}
            </div>
          {/if}
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          <!-- City -->
          Πόλη
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <input
              class="input"
              type="text"
              placeholder=""
              bind:value={roleData.city}
            />
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          <!-- Address -->
          Διεύθυνση
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <input
              class="input"
              type="text"
              placeholder=""
              bind:value={roleData.address}
            />
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          <!-- Description -->
          Περιγραφή
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <textarea
              style="height:2rem"
              class="textarea"
              placeholder=""
              bind:value={roleData.description}
            />
          </p>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          <!-- Typical Qualifications -->
          Τυπικά προσόντα
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <textarea
              style="height:2rem"
              class="textarea"
              placeholder=""
              bind:value={roleData.typicalQualifications}
            />
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          <!-- Special Qualifications -->
          Ειδικά προσόντα
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <textarea
              style="height:2rem"
              class="textarea"
              placeholder=""
              bind:value={roleData.specialQualifications}
            />
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          <!-- Personal Characteristics -->
          Χαρακτηριστικά προσωπικότητας
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <textarea
              style="height:2rem"
              class="textarea"
              placeholder=""
              bind:value={roleData.personalCharacteristics}
            />
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">
          <!-- Goals -->
          Στόχοι
          <label />
        </label>
      </div>
      <div class="field-body">
        <div class="field">
          <p class="control">
            <textarea
              style="height:2rem"
              class="textarea"
              placeholder=""
              bind:value={roleData.goals}
            />
          </p>
        </div>
      </div>
    </div>

    <div class="sticky-bottom white-fade">
      <div class="level is-mobile">
        <div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>
        <button
          class="button is-link"
          on:click={saveRole}
          class:is-loading={saving}
        >
          <SaveIcon size="1.5x" />
          <!-- &nbsp; Save -->
          &nbsp; Αποθήκευση
        </button>
        &nbsp;
        <button
          on:click={openDeleteModal}
          class="button"
        >
          <Trash2Icon size="1.5x" />
          &nbsp; Διαγραφή
        </button>
        &nbsp;
        <button class="button" on:click={openNewRoleModal}>
          <PlusIcon size="1.5x" />
          &nbsp; Νέος ρόλος
        </button>


        <!-- <button class="button">
            <PlusIcon size="1.5x" />
          </button> -->
        <!-- <button class="button">
          <Edit2Icon size="1.5x" />
        </button> -->
      </div>
    </div>
  </div>
{:else}
  <div in:fade|local class="columns is-centered">
    <div style="margin-top:5rem">
      <Spinner size="40" speed="750" thickness="2" gap="40" />
    </div>
  </div>
{/if}

<!-- add role modal -->
{#if openNewRole}
  <div class="modal" class:is-active={openNewRole}>
    <div out:fade|local on:click={cancelNewRole} class="modal-background" />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Νέος ρόλος</p>
        <button class="delete" on:click={cancelNewRole} aria-label="close" />
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">Όνομα</label>
          <div class="control">
            <input
              type="text"
              bind:value={newRoleName}
              placeholder=""
              class="input"
              autofocus
            />
          </div>
        </div>
        <!-- <div class="field">
          <label class="label">Κωδικός</label>
          <div class="control">
            <input
              type="text"
              bind:value={newRoleCode}
              placeholder=""
              class="input"
            />
          </div>
        </div> -->
      </section>
      <footer class="modal-card-foot">
        <button
          disabled={!newRoleName}
          on:click={addRole}
          class="button is-pulled-left is-link"
        >
          &nbsp; Προσθήκη
        </button>
        <button on:click={cancelNewRole} class="button is-light pull-right">
          Άκυρο
        </button>
      </footer>
    </div>
  </div>
{/if}

<!-- delete dialog -->
{#if openDelete}
  <div class="modal" class:is-active={openDelete}>
    <div
      out:fade|local
      on:click={cancelDeleteDialog}
      class="modal-background"
    />
    <div transition:fly|local={{ duration: 250, y: 10 }}  class="box modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <!-- Delete -->
          Διαγραφή
        </p>
        <button
          class="delete"
          on:click={cancelDeleteDialog}
          aria-label="close"
        />
      </header>
      <section class="modal-card-body">Διαγραφή {roleData.name} ;</section>
      <footer class="modal-card-foot">
        <button
          on:click={deleteRole}
          class="button is-pulled-left is-danger is-light"
        >
          <Trash2Icon size="1.5x" />
          <!-- &nbsp; Delete -->
          &nbsp; Διαγραφή
        </button>
        <button
          on:click={cancelDeleteDialog}
          class="button is-light pull-right"
        >
          <!-- Cancel -->
          Ακύρωση
        </button>
      </footer>
    </div>
  </div>
{/if}
